import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  progress: {
    margin: theme.spacing(0)
  }
}));

export default function LoadingSpinner() {
  const classes = useStyles();

  return (
    <div>
      <CircularProgress
        className={classes.progress}
        color="inherit"
        size={15}
      />
    </div>
  );
}
