import * as types from "./actionTypes";

export function loginUser(user) {
  return { type: types.LOGIN, user };
}

export function fetchOtp(user) {
  return { type: types.FETCH_OTP, user };
}

export function loginOtp(user) {
  return { type: types.LOGIN_OTP, user };
}

export const fetchOtpSuccess = response => {
  return {
    type: types.OTP_SUCCESS,
    response: null
  };
};

export const fetchOtpFailure = error => ({
  type: types.OTP_FAILURE,
  error
});

export function validationError(error) {
  return { type: types.ERROR, error };
}

export const loginSuccess = response => ({
  type: types.LOGIN_SUCCESS,
  response
});

export const loginFailure = error => ({
  type: types.LOGIN_FAILURE,
  error
});

export function fetchUsers(userType = "") {
  return { type: types.GET_USERS, userType };
}

export function searchUsers(searchParams) {
  return { type: types.SEARCH_USERS, searchParams };
}

export const fetchUsersSuccess = response => ({
  type: types.GET_USERS_SUCCESS,
  users: response
});

export const searchUsersSuccess = response => ({
  type: types.SEARCH_USERS_SUCCESS,
  users: response.users, totalRecords: response.totalRecords
});

export const fetchUsersFailure = error => ({
  type: types.GET_USERS_ERROR,
  error
});

export function createUser(user) {
  return { type: types.CREATE_USER, user };
}

export const createUserSuccess = response => ({
  type: types.CREATE_USERS_SUCCESS,
  response: response
});

export const createUserFailure = error => ({
  type: types.CREATE_USERS_ERROR,
  error
});

export function signup(user) {
  console.log("Sign up");
  console.log(user);
  return { type: types.SIGNUP, user };
}

export const signupSuccess = response => ({
  type: types.SIGNUP_SUCCESS,
  response: response
});

export const signupFailure = error => ({
  type: types.SIGNUP_FAILURE,
  error
});


export function updateUser(user) {
  return { type: types.UPDATE_USER, user };
}

export const updateUserSuccess = response => ({
  type: types.UPDATE_USERS_SUCCESS,
  response: response
});

export const updateUserFailure = error => ({
  type: types.UPDATE_USERS_ERROR,
  error
});
