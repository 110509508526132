/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import * as contentActions from "../../redux/actions/contentActions";
import * as userActions from "../../redux/actions/userActions";
import ContentForm from './Content-form';
import ContentSection from './Content-section';
import Modal from "../common/modal/Modal";

class ContentNewContainer extends React.Component {
  componentDidMount() {
    this.state.content = this.state.content;
  }
  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      this.state = { key: "description" };
      const { actions, response } = this.props;
      if (response !== null) {
      }
      actions.fetchContents();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      content: {
        id: 0,
        cover: {id: ""},
        title: "",
        details: "",
        position: "",
        active: true,
        uploads: [],
        sections: []
      },
      section: {
        body: "",
        category: "",
        heading: "",
        position: 1
      },
      showModal: false
    };
    this.myToast = null;
  }
   
  handleChange = (event) => {
    const { value, name } = event.target;
    let currentContent = this.state.content;

    if (name === "uploads") {
      currentContent.uploads.push(value);
    } else if (name === "active") {
      currentContent = { ...this.state.content, [name]: event.target.checked };
    } else {
      currentContent = { ...this.state.content, [name]: value };
    }
    this.setState({ content: currentContent });
  };

  handleDelete = (index) => {
    if (window.confirm("Do you want to delete this file?")) {
      let currentContent = this.state.content;
      currentContent.uploads.splice(index,1);
      this.setState({ content: currentContent });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { actions } = this.props;
    console.log(this.state.content);
    actions.createContent(this.state.content);
    if (event !== null) {
      this.myToast = toast.success(
        `The content has been created successfully`
      );
    }
  };

  handleChangeSection = (event) => {
    const { value, name } = event.target;
    let currentSection = this.state.section;

    currentSection = { ...this.state.section, [name]: value };
    this.setState({ section: currentSection });
  };

  handleSubmitSection = (event) => {
    event.preventDefault();
    this.setState(prevState => {
      const updatedContent = {
        ...prevState.content,
        sections: [...prevState.content.sections, prevState.section]
      };
      return { content: updatedContent };
    });
    this.closeModal();
  };

  handleDeleteSection = (index) => {
    if (window.confirm("Do you want to delete this section?")) {
      this.setState(prevState => {
        const updatedSections = prevState.content.sections.slice(); // Create a shallow copy of the sections array
        updatedSections.splice(index, 1); // Modify the copied array
        const updatedContent = {
          ...prevState.content,
          sections: updatedSections
        };
        return { content: updatedContent };
      });
    }
  };

  handleNewSection = (event) => {
    event.preventDefault();
    this.setState({showModal: true});
  };

  closeModal = () => {
    this.setState({showModal: false});
  };

  render() {
    const {
      subjects,
      forms,
      isLoading = false} = this.props;
    console.log(this.state);
    const {content, section, showModal = false} = this.state;

    const visibility = showModal ? "visible" : "invisible";
    
    return (
      <Fragment>
        {showModal ? (
          <Modal
            visibility={visibility}
            modalContentId="newOrderItemId"
            modalId="newOrderId"
            closeModal={this.closeModal}
            title="New Section">
            <ContentSection handleChange={this.handleChangeSection} handleSubmit={this.handleSubmitSection} content={section} />
          </Modal>
        ) : null}
        <div className="blue-strip">
          <div className="row">
            <div className="col-10">
              NEW CONTENT
            </div>
            <div className="col-2">
              <div className="edit-content">
                <button
                  className="custom-btn d-inline-flex align-items-center m-2 m-sm-0"
                  onClick={this.handleNewSection}
                >
                  New Section
                </button>                
              </div>
            </div>
          </div>
        </div>
        <ContentForm
          content={content}
          forms={forms}
          subjects={subjects}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          isLoading={isLoading}
          handleDelete={this.handleDelete}
          handleDeleteSection={this.handleDeleteSection}
        />
      </Fragment>
    );
  }
}

ContentNewContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  cActions: PropTypes.object.isRequired,
  content: PropTypes.object,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingContents: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  contents: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
  subjects: PropTypes.array.isRequired,
  sActions: PropTypes.object.isRequired,
  fActions: PropTypes.object.isRequired,
  forms: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    contents: state.contents.contents,
    totalRecords: state.contents.totalRecords,
    categories: state.contents.categories,
    users: state.contents.users,
    error: state.contents.error,
    subjects: state.contents.subjects,
    forms: state.contents.forms,
    isLoadingContents: state.contents.isLoadingContents,
    isLoading: state.contents.isLoading,
    showModal: state.contents.showModal,
    content: state.contents.content,
    response: state.contents.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(contentActions, dispatch), cActions: bindActionCreators(userActions, dispatch)  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentNewContainer);
