import * as types from "./actionTypes";

export function fetchEvent(eventId) {
  return { type: types.GET_EVENTS_DETAILS, eventId };
}

export const fetchEventDetailsSuccess = response => ({
  type: types.GET_EVENTS_DETAILS_SUCCESS,
  event: response
});

export const fetchEventDetailsFailure = error => ({
  type: types.GET_EVENTS_DETAILS_ERROR,
  error
});

export function fetchEvents(searchParams) {
  return { type: types.GET_EVENTS,searchParams };
}

export function validationError(error) {
  return { type: types.ERROR, error };
}

export function fetchEventsSuccess(response) {
  return { type: types.GET_EVENTS_SUCCESS,events: response, totalRecords: response.length }
};

export const fetchEventsFailure = error => ({
  type: types.GET_EVENTS_ERROR,
  error
});

export const fetchEventCategoriesSuccess = response => ({
  type: types.GET_EVENT_CATEGORIES_SUCCESS,
  categories: response
});

export const fetchEventCategoriesFailure = error => ({
  type: types.GET_EVENT_CATEGORIES_ERROR,
  error
});

export function createEvent(event) {
  return { type: types.CREATE_EVENT, event };
}

export const createEventSuccess = response => ({
  type: types.CREATE_EVENTS_SUCCESS,
  response: response
});

export const createEventFailure = error => ({
  type: types.CREATE_EVENTS_ERROR,
  error
});

export function updateEvent(event) {
  return { type: types.UPDATE_EVENT, event };
}

export const updateEventSuccess = response => ({
  type: types.UPDATE_EVENTS_SUCCESS,
  response: response
});

export const updateEventFailure = error => ({
  type: types.UPDATE_EVENTS_ERROR,
  error
});

export const hideModal = () => ({
  type: types.CLOSE_MODAL
});
