import resolveBaseUrl from "../services";
import moment from 'moment';

const baseUrl = resolveBaseUrl();

const getFile = (fileName) => {
    return `${baseUrl}/files/${fileName}`;
};

export const Settings = {
    getFile
};

export const randomPassword = () => {
  const min = Math.ceil(100000);
  const max = Math.floor(999999);
  return Math.floor(Math.random() * (max - min) + min);
}

export const formattedDateTime = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
    return formattedDate;
}

export const formattedDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
}

export const  convertDate = (dateStr) => {
    var parts = dateStr.split(' ');
    var dateParts = parts[0].split('-');
    var timeParts = parts[1].split(':');
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], timeParts[0], timeParts[1]);
  }

  export const  convertShortDate = (dateStr) => {
    var parts = dateStr;
    var dateParts = parts.split('-');
    return new Date(dateParts[0], dateParts[1] - 1, dateParts[2], 13, 1);
  }

export const formatDate = (value) => {
    if (value) {
      return moment(String(value)).format('MMMM Do YYYY, HH:MM')
    }
  }