import React from "react";
import { Redirect } from "react-router-dom";

const Logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  document.dispatchEvent(new CustomEvent("logout", { detail: false }));
  return <Redirect to='/admin' />;
};

export default Logout;
