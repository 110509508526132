import { put, takeLatest, call } from "redux-saga/effects";
import { GET_LITTLEMEMBERS, CREATE_LITTLEMEMBER, UPDATE_LITTLEMEMBER, SEARCH_LITTLEMEMBERS, SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE, CREATE_LITTLEMEMBERATTENDANCE } from "../actions/actionTypes";
import LittleMemberAPI from "../../services/LittleMemberAPI";
import {
  fetchLittleMembersSuccess,
  fetchLittleMembersFailure,
  createLittleMemberSuccess,
  createLittleMemberFailure,
  updateLittleMemberSuccess,
  updateLittleMemberFailure,
  searchLittleMembersSuccess,
  createLittleMemberAttendance,
  createLittleMemberAttendanceFailure,
  createLittleMemberAttendanceSuccess
} from "../actions/littleMemberActions";
import apiErrorHandler from "../../services/apiErrorHandler";

export function* fetchLittleMembersAsync(action) {
  try {
    const response = yield call(LittleMemberAPI.fetch, action.littleMemberType);
    yield put(fetchLittleMembersSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(fetchLittleMembersFailure(errorMessage));
  }
}

export function* searchLittleMembersAsync(action) {
  try {
    const response = yield call(LittleMemberAPI.search, action.searchParams);
    yield put(searchLittleMembersSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(fetchLittleMembersFailure(errorMessage));
  }
}

export function* createLittleMemberAsync(action) {
  try {
    const response = yield call(LittleMemberAPI.createLittleMember, action.littleMember);
    yield put(createLittleMemberSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(createLittleMemberFailure(errorMessage));
  }
}

export function* updateLittleMemberAsync(action) {
  try {
    const response = yield call(LittleMemberAPI.updateLittleMember, action.littleMember);
    yield put(updateLittleMemberSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(updateLittleMemberFailure(errorMessage));
  }
}

export function* createLittleMemberAttendanceAsync(action) {
  try {
    const response = yield call(LittleMemberAPI.createLittleMemberAttendance, action.littleMemberAttendance);
    yield put(createLittleMemberAttendanceSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(createLittleMemberAttendanceFailure(errorMessage));
  }
}

export function* watchSearchLittleMembers() {
  yield takeLatest(SEARCH_LITTLEMEMBERS, searchLittleMembersAsync);
}

export function* watchLittleMembers() {
  yield takeLatest(GET_LITTLEMEMBERS, fetchLittleMembersAsync);
}

export function* watchNewLittleMember() {
  yield takeLatest(CREATE_LITTLEMEMBER, createLittleMemberAsync);
}

export function* watchNewLittleMemberAttendance() {
  yield takeLatest(CREATE_LITTLEMEMBERATTENDANCE, createLittleMemberAttendanceAsync);
}

export function* watchUpdateLittleMember() {
  yield takeLatest(UPDATE_LITTLEMEMBER, updateLittleMemberAsync);
}

