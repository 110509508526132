export const isAuth = () => localStorage.getItem("token") !== null;
export const processLoginResponse = response => {
  localStorage.setItem("token", response.headers.authorization);
  localStorage.setItem("user", response.data);
};

export const processOtpLoginResponse = response => {
  const userResponse = response.data;
  console.log(userResponse);
  localStorage.setItem("token", userResponse.accessToken);
  localStorage.setItem("user", JSON.stringify(userResponse));
  localStorage.setItem("roles", userResponse.roles.join(","));
};

export const logout = () => {
  localStorage.removeItem("token")
  localStorage.removeItem("user");
  localStorage.removeItem("roles");
};

export const isAdmin = () => {
  let result = false;
  
  if (isAuth() && localStorage.getItem("roles") !== null) {
    result = (localStorage.getItem("roles").includes("ADMIN") || localStorage.getItem("roles").includes("ROLE_ADMIN"));
  }

  return result;
};
