import { browserHistory } from 'react-router';
import { put, takeLatest, call } from "redux-saga/effects";
import { GET_EVENTS, CREATE_EVENT, GET_EVENTS_DETAILS, UPDATE_EVENT } from "../actions/actionTypes";
import EventAPI from "../../services/EventAPI";
import {
  fetchEventsSuccess,
  fetchEventsFailure,
  createEventSuccess,
  createEventFailure,
  updateEventSuccess,
  updateEventFailure,
  fetchEventDetailsSuccess,
  fetchEventDetailsFailure
} from "../actions/eventActions";
import apiErrorHandler from "../../services/apiErrorHandler";

export function* fetchEventsAsync(action) {
  try {
    const response = yield call(EventAPI.fetch, action.searchParams);
    yield put(fetchEventsSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(fetchEventsFailure(errorMessage));
  }
}

export function* fetchEventDetailsAsync(action) {
  try {
    const response = yield call(EventAPI.fetchEvent, action.eventId);
    yield put(fetchEventDetailsSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(fetchEventDetailsFailure(errorMessage));
  }
}

export function* createEventAsync(action) {
  try {
    const response = yield call(EventAPI.createEvent, action.event);
    yield put(createEventSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(createEventFailure(errorMessage));
  }
}

export function* updateEventAsync(action) {
  try {
    const response = yield call(EventAPI.editEvent, action.event);
    yield put(updateEventSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(updateEventFailure(errorMessage));
  }
}

export function* watchEvents() {
  yield takeLatest(GET_EVENTS, fetchEventsAsync);
}

export function* watchEventDetails() {
  yield takeLatest(GET_EVENTS_DETAILS, fetchEventDetailsAsync);
}

export function* watchCreateEvent() {
  yield takeLatest(CREATE_EVENT, createEventAsync);
}

export function* watchUpdateEvent() {
  yield takeLatest(UPDATE_EVENT, updateEventAsync);
}
