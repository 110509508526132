import * as types from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  response: null,
  error: "",
  showModal: false,
  showOtpScreen: false,
  totalRecords: 0,
  users: [],
  categories: [],
  user: { email: "", password: "",phone: "", username: "" },
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOGIN:
      return { ...state, user: action.user, isLoading: true };
    case types.FETCH_OTP:
      return { ...state, user: action.user, isLoading: true };
    case types.LOGIN_OTP:
      return { ...state, user: action.user, isLoading: true, showOtpScreen: true };
    case types.OTP_SUCCESS:
      return { ...state, isLoading: false, response: action.response, showOtpScreen: true };
    case types.ERROR:
    case types.LOGIN_FAILURE:
      return { ...state, error: action.error, isLoading: false };
    case types.LOGIN_SUCCESS:
      return { ...state, isLoading: false, response: action.response };
    case types.SIGNUP:
      return { ...state, isLoading: true };
    case types.SIGNUP_FAILURE:
      return { ...state, error: action.error, isLoading: false };
    case types.SIGNUP_SUCCESS:
      return { ...state, isLoading: false, response: action.response };
    case types.GET_USERS:
      return { ...state, isLoading: true };
    case types.SEARCH_USERS:
      return { ...state, isLoading: true };
    case types.GET_USERS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
        isLoading: false
      };
    case types.SEARCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
        totalRecords: action.totalRecords,
        isLoading: false
      };
    case types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        isLoading: false
      };
    case types.CREATE_USER:
      return { ...state, user: action.user };
    case types.CREATE_USERS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.CREATE_USERS_SUCCESS:
      return {
        ...state,
        response: action.response,
        user: action.response,
        isLoading: false,
        showModal: false,
        error: ""
      };
      case types.UPDATE_USER:
        return { ...state, isLoading: true };
      case types.UPDATE_USERS_ERROR:
        return { ...state, error: action.error, isLoading: false };
      case types.UPDATE_USERS_SUCCESS:
        return {
          ...state,
          response: action.response,
          isLoading: false,
          showModal: false,
          error: ""
        };
    default:
      return state;
  }
}
