/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import * as eventActions from "../../redux/actions/eventActions";
import EventForm from "./Event-form";
import Preloader from "../Preloader/Preloader";

class EventEditContainer extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.fetchEvent(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      this.state = { key: "description" };
      const { actions, response } = this.props;
      if (response !== null) {
      }
      actions.fetchEvents();
    }

    if (this.state.event == null && this.props.event != null) {
      this.setState({
        event: this.props.event
      });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      event: null,
      section: {
        body: "",
        category: "",
        heading: "",
        position: 1,
      },
      showModal: false,
    };
    this.myToast = null;
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    if (this.state.content == null) {
      this.setState({ event: this.props.event });
      this.state.event = this.props.event;
    }
    let currentEvent = this.state.event;

    if (name === "uploads") {
      currentEvent.uploads.push(value);
    } else if (name === "active") {
      currentEvent = { ...this.state.event, [name]: event.target.checked };
    } else {
      currentEvent = { ...this.state.event, [name]: value };
    }

    this.setState({ event: currentEvent });
  };

  handleDelete = (index) => {
    if (window.confirm("Do you want to delete this file?")) {
      let currentEvent = this.state.event;
      currentEvent.uploads.splice(index, 1);
      this.setState({ event: currentEvent });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { actions } = this.props;
    let event = this.state.event != null ? this.state.event : this.props.event;

    event.cover = {id: event.cover.id};
    actions.updateEvent(event);
    if (e !== null) {
      this.myToast = toast.success(`The event has been updated successfully`);
    }
  };

  closeModal = () => {
    const { actions } = this.props;
    actions.hideModal();
  };

  render() {
    const { isLoading = true, error = "", uploads = [] } = this.props;
    const {event = null} = this.state;
    
    return isLoading || event == null ? (
      <>
        <Preloader/>
      </>
    ) : (
      <Fragment>
        <div className="blue-strip">
          <div className="row">
            <div className="col-10">EDIT CONTENT</div>
            <div className="col-2">
              <div className="edit-event"></div>
            </div>
          </div>
        </div>
        <EventForm
          event={event}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          isLoading={isLoading}
          handleDelete={this.handleDelete}
        />
      </Fragment>
    );
  }
}

EventEditContainer.propTypes = {
  event: PropTypes.object,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingEvents: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  console.log(state);
  return {
    totalRecords: state.events.totalRecords,
    users: state.events.users,
    error: state.events.error,
    isLoadingEvents: state.events.isLoadingEvents,
    isLoading: state.events.isLoading,
    showModal: state.events.showModal,
    event: state.events.event,
    response: state.events.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(eventActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EventEditContainer);
