/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import withLoading from "../Hoc/withLoading";
import countriesLoader from "../common/Contentloader";
import "./Styles.scss";

const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  container: {
    maxHeight: 640
  }
});

class List extends React.Component {
  handleChangePage = () => {};
  handleChangeRowsPerPage = () => {};
  handleItemClick = (id) => {
    console.log(id);
  }
  render() {
    const classes = useStyles;
    const columnHeaders = [
      { id: 1, align: "left", label: "Name", name: "name" },
      { id: 2, align: "left", label: "Details", name: "details" }
    ];
    const { records } = this.props;
    const page = 0;
    const rowsPerPage = 100;
    return (
      <Fragment>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label='sticky table'>
              <TableHead>
                <TableRow>
                  {columnHeaders.map(column => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {records.map(row => (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                        <TableCell align="left">
                          <a href={`/serviceareas/${row.id}`}>{row.name}</a>
                        </TableCell>
                        <TableCell align="left">
                          {row.details}
                        </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component='div'
            count={records.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
      </Fragment>
    );
  }
}

export default withLoading(List, countriesLoader);
