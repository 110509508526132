import axios from "axios";
import resolveBaseUrl from ".";

const baseUrl = resolveBaseUrl();

class UploadAPI {
  static upload(file, handleResult, config) {
    const formData = new FormData(); 

    formData.append( 
      "file", 
      file, 
      file.name 
    ); 
    let response = "";
    axios.post(`${baseUrl}/upload`, formData, config).then((result) => {
      handleResult(result);
    });
    return response;
  }

  static uploadChunked(formData, handleResult, config) {
    let response = "";
    axios.post(`${baseUrl}/uploadchunks`, formData, config).then((result) => {
      handleResult(result);
    });
    return response;
  }
}

export default UploadAPI;
