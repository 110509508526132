import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Image1 from "../../assets/images/slider/image1.jpg";
import Image2 from "../../assets/images/slider/image2.jpg";

function About(props) {
  return (
		<section id="about">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-10">
						<div className="section-title text-center text-vision">
							<div>We <strong>encounter Jesus</strong> through the power of <strong>the Holy Spirit</strong> and <strong>have dominion.</strong> </div>
						</div>
						
					</div>
				</div>
			
				<div className="row">
					<div className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0">
						<div className="service-purple">
							<p>MAKE A</p>
							<p className="strong-5x">DECISION</p>
							<p>TO FOLLOW JESUS</p>
						</div>
					</div>
					<div className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.6s">
						<div className="service-purple service-custom-1">
							<p>RECEIVE</p>
							<p className="strong-5x">DELIVERANCE</p>
							<p>FROM PAST BONDAGE</p>
						</div>
					</div>
					<div className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.3s">
						<div className="service-purple service-custom-2">
							<p>EMPOWER</p>
							<p className="strong-5x">TO INFLUENCE</p>
							<p>AND MULTIPLY</p>
						</div>
					</div>
				</div>
				<br/>
				<div className="section-divider"></div>		
				<div className="row">
					<div className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.3s">
						<div className="service-purple service-custom-3">
							<p className="strong-5x">DISCOVER</p>
							<p>YOUR PURPOSE</p>
						</div>
					</div>
					<div className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0.6s">
						<div className="service-blank">
							<p>&nbsp;</p>
							<p className="strong-5x">OUR VALUES</p>
						</div>
					</div>
					<div className="col-12 col-lg-4 res-margin wow fadeInUp" data-wow-offset="10" data-wow-duration="1s" data-wow-delay="0">
						<div className="service-purple service-custom-4">
							<p>HAVE</p>
							<p className="strong-5x">DOMINION</p>
						</div>
					</div>
				</div>		
			</div>		
		</section>
  );
}

export default About;
