/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import { Link } from "react-router-dom";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import withLoading from "../Hoc/withLoading";
import littleMembersLoader from "../common/Contentloader";
import Pagination from "react-js-pagination";
import { formattedDate, formatDate } from "../../utils/Settings";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 640,
  },
});

class LittleMemberList extends React.Component {
  handleChangePage = () => {};
  handleChangeRowsPerPage = () => {};
  getCategoryName = (id) => {
    const { categories } = this.props;
    const category = categories.filter((category) => category.id === id)[0];
    return category.name;
  };
  render() {
    const classes = useStyles;
    const columnHeaders = [
      { id: 1, align: "left", label: "Parent's Name", name: "name" },
      { id: 2, align: "left", label: "Child's Name", name: "firstname" },
      { id: 3, align: "left", label: "Phone", name: "phone" },
      { id: 4, align: "left", label: "Email", name: "email" },
      { id: 5, align: "left", label: "Last Attended", name: "attendance" },
      { id: 6, align: "left", label: "", name: "actions" },
    ];
    const {
      littleMembers,
      activePage = 1,
      totalItemsCount = 0,
      itemsCountPerPage = 30,
      handlePageClick = () => {},
      handleChildrenClick,
    } = this.props;
    const page = 0;
    const rowsPerPage = 100;
    return (
      <Fragment>
        <Paper className={classes.root}>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columnHeaders.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {littleMembers.map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                    <TableCell>
                      {row.user ? (
                        <Link
                          to={{
                            pathname: "/user/edit/",
                            state: {
                              user: row.user,
                            },
                          }}
                        >
                          {row.user.name}
                        </Link>
                      ) : (
                        <></>
                      )}
                    </TableCell>
                    <TableCell>
                      {row.firstname} {row.lastname}{" "}
                    </TableCell>
                    <TableCell>{row.user ? row.user.phone : ""}</TableCell>
                    <TableCell>{row.user ? row.user.email : ""}</TableCell>
                    <TableCell>
                      {row.attendances &&
                      row.attendances.length > 0 &&
                      row.attendances[0].arrivalDateTime
                        ? formatDate(row.attendances[0].arrivalDateTime)
                        : ""}
                    </TableCell>
                    <TableCell>
                      <Link
                        to={{
                          pathname: "/littleMember/edit/",
                          state: {
                            littleMember: row,
                          },
                        }}
                      >
                        Edit
                      </Link>
                      &nbsp;&nbsp;&nbsp;
                      <a
                        className="btn-action-primary"
                        href="#"
                        onClick={() => {
                          handleChildrenClick(row);
                        }}
                      >
                        Attendance
                      </a>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <div className="pagination-wrapper row">
          <div className="col-4 label">
            Found {totalItemsCount} record{totalItemsCount == 1 ? "" : "s"}
          </div>
          <div className="col">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              pageRangeDisplayed={5}
              onChange={(pageNumber) => {
                handlePageClick(pageNumber);
              }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withLoading(LittleMemberList, littleMembersLoader);
