import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import "./App.scss";
import "./Custom.css";
import "../../assets/css/fontawesome.min.css";
import "../../assets/css/blue.css";
import "../../assets/css/media.css";
import "../../assets/library/linea/arrows/styles.css";
import "../../assets/library/linea/basic/styles.css";
import "../../assets/library/linea/ecommerce/styles.css";
import "../../assets/library/linea/software/styles.css";
import "../../assets/library/linea/weather/styles.css";
import { BrowserRouter as Router } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Copyright from "../common/Footer";
import Routes from "../../routes";
import PrimarySearchAppBar from "../common/AppBar";
import AnonAppBar from "../common/AppBarAnon";
import { isAuth } from "../../utils/Authentication";

function App() {
  return (
    <div id='root'>
      <Router>
        <div>
          <AnonAppBar />
          <div className='container-fluid main-area'>
            <Routes />
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </div>
      </Router>
    </div>
  );
}

export default App;
