import * as types from "./actionTypes";

export function fetchServiceArea(serviceAreaId) {
  return { type: types.GET_SERVICEAREAS_DETAILS, serviceAreaId };
}

export const fetchServiceAreaDetailsSuccess = response => ({
  type: types.GET_SERVICEAREAS_DETAILS_SUCCESS,
  serviceArea: response
});

export const fetchServiceAreaDetailsFailure = error => ({
  type: types.GET_SERVICEAREAS_DETAILS_ERROR,
  error
});

export function fetchServiceAreas() {
  return { type: types.GET_SERVICEAREAS };
}

export function validationError(error) {
  return { type: types.ERROR, error };
}

export const fetchServiceAreasSuccess = response => ({
  type: types.GET_SERVICEAREAS_SUCCESS,
  serviceAreas: response
});

export const fetchServiceAreasFailure = error => ({
  type: types.GET_SERVICEAREAS_ERROR,
  error
});

export function createServiceArea(record) {
  return { type: types.CREATE_SERVICEAREA, record };
}

export const createServiceAreaSuccess = response => ({
  type: types.CREATE_SERVICEAREAS_SUCCESS,
  response: response
});

export const createServiceAreaFailure = error => ({
  type: types.CREATE_SERVICEAREAS_ERROR,
  error
});

export function createVolunteer(record, id) {
  return { type: types.CREATE_VOLUNTEER, record, id };
}

export const createVolunteerSuccess = response => ({
  type: types.CREATE_VOLUNTEER_SUCCESS,
  response: response
});

export const createVolunteerFailure = error => ({
  type: types.CREATE_VOLUNTEER_ERROR,
  error
});

export function createChecklistTemplate(record) {
  return { type: types.CREATE_CHECKLISTTEMPLATE, record };
}

export const createChecklistTemplateSuccess = response => ({
  type: types.CREATE_CHECKLISTTEMPLATES_SUCCESS,
  response: response
});

export const createChecklistTemplateFailure = error => ({
  type: types.CREATE_CHECKLISTTEMPLATES_ERROR,
  error
});

export function createChecklistTemplateItem(record) {
  return { type: types.CREATE_CHECKLISTTEMPLATE_ITEM, record };
}

export const createChecklistTemplateItemSuccess = response => ({
  type: types.CREATE_CHECKLISTTEMPLATE_ITEMS_SUCCESS,
  response: response
});

export const createChecklistTemplateItemFailure = error => ({
  type: types.CREATE_CHECKLISTTEMPLATE_ITEMS_ERROR,
  error
});
