import * as types from "./actionTypes";

export function fetchLittleMembers(littleMemberType = "") {
  return { type: types.GET_LITTLEMEMBERS, littleMemberType };
}

export function searchLittleMembers(searchParams) {
  return { type: types.SEARCH_LITTLEMEMBERS, searchParams };
}

export const fetchLittleMembersSuccess = response => ({
  type: types.GET_LITTLEMEMBERS_SUCCESS,
  littleMembers: response
});

export const searchLittleMembersSuccess = response => ({
  type: types.SEARCH_LITTLEMEMBERS_SUCCESS,
  littleMembers: response,
  totalRecords: response.length,
});

export const fetchLittleMembersFailure = error => ({
  type: types.GET_LITTLEMEMBERS_ERROR,
  error
});

export function createLittleMember(littleMember) {
  return { type: types.CREATE_LITTLEMEMBER, littleMember };
}

export const createLittleMemberSuccess = response => ({
  type: types.CREATE_LITTLEMEMBERS_SUCCESS,
  response: response
});

export const createLittleMemberFailure = error => ({
  type: types.CREATE_LITTLEMEMBERS_ERROR,
  error
});

export function createLittleMemberAttendance(littleMemberAttendance) {
  return { type: types.CREATE_LITTLEMEMBERATTENDANCE, littleMemberAttendance };
}

export const createLittleMemberAttendanceSuccess = response => ({
  type: types.CREATE_LITTLEMEMBERATTENDANCES_SUCCESS,
  response: response
});

export const createLittleMemberAttendanceFailure = error => ({
  type: types.CREATE_LITTLEMEMBERATTENDANCES_ERROR,
  error
});

export function updateLittleMember(littleMember) {
  return { type: types.UPDATE_LITTLEMEMBER, littleMember };
}

export const updateLittleMemberSuccess = response => ({
  type: types.UPDATE_LITTLEMEMBERS_SUCCESS,
  response: response
});

export const updateLittleMemberFailure = error => ({
  type: types.UPDATE_LITTLEMEMBERS_ERROR,
  error
});
