import React from "react";
import { Link } from "react-router-dom";
import UploadAPI from "../../services/UploadAPI";

export default function Upload(props) {
  const { handleSubmit, multiple = false, title = null } = props;
  const [file, setFile] = React.useState("");
  const [progress, setProgress] = React.useState(0);
  const handleResult = (result) => {
    handleSubmit(result);
  };

  const config = {
    onUploadProgress: (progressEvent) => {
      setProgress((progressEvent.loaded/progressEvent.total) * 100)
    },
  };

  function handleUpload(event) {
    setFile(event.target.files[0]);
    UploadAPI.upload(event.target.files[0], handleResult, config);
  }

  return (
    <>
    {title !== null ? (
      <p>{title}</p>
    ) : ("")}
    <div className="row">
      <div className="col-10">
        <input type="file" onChange={handleUpload} className="form-control" />
      </div>
      <div className="col-2">{Math.round(progress)}%</div>
    </div>
    </>
  );
}
