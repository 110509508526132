/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import {useParams} from 'react-router-dom';
import * as contentActions from "../../../redux/actions/contentActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "../../Preloader/Preloader";

import Featured from "./Featured";
import "../../../assets/css/media.css";


toast.configure({
  autoClose: 8000,
  draggable: false,
});

class GeneralContent extends React.Component {
  componentDidMount() {
    const { actions} = this.props;
    const queryParams = new URLSearchParams(window.location.search)
    actions.fetchContents({
      category: this.category,
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response } = this.props;
      if (response !== null) {
        this.myToast = toast.success(
          `${response.name} has been saved successfully`
        );
      }
    }
  }

  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search)
    this.category = queryParams.get("category");
    this.activePage = 1;
    this.search = {
      category: "",
      title: "",
      page: this.activePage
    };

    this.myToast = null;
  }


  render() {
    const {
      contents = [],
      isLoadingContents = true,
      error = "",
    } = this.props;

    if (error.length > 0) {
      this.myToast = toast.error(error);
    }

    return (isLoadingContents) ? <Preloader /> : (
      <>
        <Featured contents={contents} category={this.category} />
      </>
    );
  }
}

GeneralContent.propTypes = {
  actions: PropTypes.object.isRequired,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingContents: PropTypes.bool.isRequired,
  contents: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    contents: state.contents.contents,
    totalRecords: state.contents.totalRecords,
    error: state.contents.error,
    isLoading: state.contents.isLoading,
    isLoadingContents: state.contents.isLoadingContents,
    response: state.contents.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(contentActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralContent);