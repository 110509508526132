/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import { formattedDateTime, convertDate } from "../../utils/Settings";
import DatePicker from "react-datepicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedRoles: [] };
  }

  
  render() {
    const classes = useStyles;
    const { handleSubmit, handleChange, event } = this.props;
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <form className={classes.root} noValidate autoComplete="off">
              <div className="row">
                <div className="col-3">
                  <input
                    placeholder="Title"
                    className="form-control"
                    onChange={handleChange}
                    value={event.title}
                    name="title"
                  />
                </div>
                <div className="col-2">
                  <DatePicker
                    name="startDateTime"
                    valueName="selected"
                    placeholderText="Start Date &amp; Time"
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    showTimeSelect
                    timeFormat="HH:mm"
                    portalId="root-portal"
                    value={event.startDateTime ? event.startDateTime : ""}
                    selected={event.startDateTime ? convertDate(event.startDateTime) : new Date()}
                    onChange={(date) => {
                      const event = {
                        target: {
                          name: "startDateTime",
                          value: formattedDateTime(date),
                        },
                      };
                      handleChange(event);
                    }} //only when value has changed
                  />
                </div>
                <div className="col-2">
                  <DatePicker
                    name="endDateTime"
                    valueName="selected"
                    placeholderText="End Date &amp; Time"
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    showTimeSelect
                    timeFormat="HH:mm"
                    portalId="root-portal"
                    value={event.endDateTime ? event.endDateTime : ""}
                    selected={event.endDateTime ? convertDate(event.endDateTime) : new Date()}
                    onChange={(date) => {
                      const event = {
                        target: {
                          name: "endDateTime",
                          value: formattedDateTime(date),
                        },
                      };
                      handleChange(event);
                    }} //only when value has changed
                  />
                </div>
                <div className="col-2">
                  <select
                      name="type"
                      className="form-control"
                      onChange={handleChange}
                      value={event.type}
                      
                    >
                    <option value="">Category</option>
                      {["Public", "Internal", "Invite Only"].map((s) => (
                        <option value={s} key={`t${s}`}>
                          {s}
                        </option>
                        ))}
                  </select>
                </div>
                <div className="col-1">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => handleSubmit(event)}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SearchForm;
