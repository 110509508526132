export const LOGIN = "LOGIN";
export const SIGNUP = "SIGNUP";
export const ERROR = "ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const OTP_SUCCESS = "OTP_SUCCESS";
export const OTP_FAILURE = "OTP_FAILURE";
export const FETCH_OTP = "FETCH_OTP";
export const LOGIN_OTP = "LOGIN_OTP";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SHOW_MODAL = "SHOW_MODAL";

export const GET_CONTENTS = "GET_CONTENTS";
export const GET_CONTENTS_SUCCESS = "GET_CONTENTS_SUCCESS";
export const GET_CONTENTS_ERROR = "GET_CONTENTS_ERROR";
export const GET_CONTENT_CATEGORIES_SUCCESS = "GET_CONTENT_CATEGORIES_SUCCESS";
export const GET_CONTENT_CATEGORIES_ERROR = "GET_CONTENT_CATEGORIES_ERROR";
export const CREATE_CONTENTS_SUCCESS = "CREATE_CONTENTS_SUCCESS";
export const CREATE_CONTENTS_ERROR = "CREATE_CONTENTS_ERROR";
export const CREATE_CONTENT = "CREATE_CONTENT";
export const GET_CONTENTS_DETAILS = "GET_CONTENTS_DETAILS";
export const GET_CONTENTS_DETAILS_SUCCESS = "GET_CONTENTS_DETAILS_SUCCESS";
export const GET_CONTENTS_DETAILS_ERROR = "GET_CONTENTS_DETAILS_ERROR";
export const UPDATE_CONTENTS_SUCCESS = "UPDATE_CONTENTS_SUCCESS";
export const UPDATE_CONTENTS_ERROR = "UPDATE_CONTENTS_ERROR";
export const UPDATE_CONTENT = "UPDATE_CONTENT";

export const GET_USERS = "GET_USERS";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_ERROR = "GET_USERS_ERROR";
export const CREATE_USERS_SUCCESS = "CREATE_USERS_SUCCESS";
export const CREATE_USERS_ERROR = "CREATE_USERS_ERROR";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USERS_SUCCESS = "UPDATE_USERS_SUCCESS";
export const UPDATE_USERS_ERROR = "UPDATE_USERS_ERROR";
export const UPDATE_USER = "UPDATE_USER";
export const SEARCH_USERS = "SEARCH_USERS";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";

export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_ERROR = "GET_CATEGORIES_ERROR";
export const CREATE_CATEGORIES_SUCCESS = "CREATE_CATEGORIES_SUCCESS";
export const CREATE_CATEGORIES_ERROR = "CREATE_CATEGORIES_ERROR";
export const CREATE_CATEGORY = "CREATE_CATEGORY";

export const GET_SERVICEAREAS = "GET_SERVICEAREAS";
export const GET_SERVICEAREAS_SUCCESS = "GET_SERVICEAREAS_SUCCESS";
export const GET_SERVICEAREAS_ERROR = "GET_SERVICEAREAS_ERROR";
export const CREATE_SERVICEAREAS_SUCCESS = "CREATE_SERVICEAREAS_SUCCESS";
export const CREATE_SERVICEAREAS_ERROR = "CREATE_SERVICEAREAS_ERROR";
export const CREATE_SERVICEAREA = "CREATE_SERVICEAREA";
export const GET_SERVICEAREAS_DETAILS = "GET_SERVICEAREAS_DETAILS";
export const GET_SERVICEAREAS_DETAILS_SUCCESS = "GET_SERVICEAREAS_DETAILS_SUCCESS";
export const GET_SERVICEAREAS_DETAILS_ERROR = "GET_SERVICEAREAS_DETAILS_ERROR";

export const CREATE_VOLUNTEER_SUCCESS = "CREATE_VOLUNTEER_SUCCESS";
export const CREATE_VOLUNTEER_ERROR = "CREATE_VOLUNTEER_ERROR";
export const CREATE_VOLUNTEER = "CREATE_VOLUNTEER";

export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_SUCCESS = "GET_EVENTS_SUCCESS";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";
export const GET_EVENT_CATEGORIES_SUCCESS = "GET_EVENT_CATEGORIES_SUCCESS";
export const GET_EVENT_CATEGORIES_ERROR = "GET_EVENT_CATEGORIES_ERROR";
export const CREATE_EVENTS_SUCCESS = "CREATE_EVENTS_SUCCESS";
export const CREATE_EVENTS_ERROR = "CREATE_EVENTS_ERROR";
export const CREATE_EVENT = "CREATE_EVENT";
export const GET_EVENTS_DETAILS = "GET_EVENTS_DETAILS";
export const GET_EVENTS_DETAILS_SUCCESS = "GET_EVENTS_DETAILS_SUCCESS";
export const GET_EVENTS_DETAILS_ERROR = "GET_EVENTS_DETAILS_ERROR";
export const UPDATE_EVENTS_SUCCESS = "UPDATE_EVENTS_SUCCESS";
export const UPDATE_EVENTS_ERROR = "UPDATE_EVENTS_ERROR";
export const UPDATE_EVENT = "UPDATE_EVENT";

export const GET_LITTLEMEMBERS = "GET_LITTLEMEMBERS";
export const GET_LITTLEMEMBERS_SUCCESS = "GET_LITTLEMEMBERS_SUCCESS";
export const GET_LITTLEMEMBERS_ERROR = "GET_LITTLEMEMBERS_ERROR";
export const CREATE_LITTLEMEMBERS_SUCCESS = "CREATE_LITTLEMEMBERS_SUCCESS";
export const CREATE_LITTLEMEMBERS_ERROR = "CREATE_LITTLEMEMBERS_ERROR";
export const CREATE_LITTLEMEMBER = "CREATE_LITTLEMEMBER";
export const UPDATE_LITTLEMEMBERS_SUCCESS = "UPDATE_LITTLEMEMBERS_SUCCESS";
export const UPDATE_LITTLEMEMBERS_ERROR = "UPDATE_LITTLEMEMBERS_ERROR";
export const UPDATE_LITTLEMEMBER = "UPDATE_LITTLEMEMBER";
export const SEARCH_LITTLEMEMBERS = "SEARCH_LITTLEMEMBERS";
export const SEARCH_LITTLEMEMBERS_SUCCESS = "SEARCH_LITTLEMEMBERS_SUCCESS";

export const CREATE_LITTLEMEMBERATTENDANCES_SUCCESS = "CREATE_LITTLEMEMBERATTENDANCES_SUCCESS";
export const CREATE_LITTLEMEMBERATTENDANCES_ERROR = "CREATE_LITTLEMEMBERATTENDANCES_ERROR";
export const CREATE_LITTLEMEMBERATTENDANCE = "CREATE_LITTLEMEMBERATTENDANCE";

export const CREATE_CHECKLISTTEMPLATES_SUCCESS = "CREATE_CHECKLISTTEMPLATES_SUCCESS";
export const CREATE_CHECKLISTTEMPLATES_ERROR = "CREATE_CHECKLISTTEMPLATES_ERROR";
export const CREATE_CHECKLISTTEMPLATE = "CREATE_CHECKLISTTEMPLATE";

export const CREATE_CHECKLISTTEMPLATE_ITEMS_SUCCESS = "CREATE_CHECKLISTTEMPLATE_ITEMS_SUCCESS";
export const CREATE_CHECKLISTTEMPLATE_ITEMS_ERROR = "CREATE_CHECKLISTTEMPLATE_ITEMS_ERROR";
export const CREATE_CHECKLISTTEMPLATE_ITEM = "CREATE_CHECKLISTTEMPLATE_ITEM";