/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "./Contents.scss";

class ContentSectionList extends React.Component {

  render() {
    const { contents, handleDelete } = this.props;
    return (
      <Fragment>
        <ul className="list-group">
          {contents && contents.map((content, index) => {
            return CategoryCard(content, index, handleDelete);
          })}
        </ul>
      </Fragment>
    );
  }
}

function CategoryCard(content, index, handleDelete) {
  return (
    <li className="list-group-item" key={index}>
      <div><b>{content.heading}</b> <button className="btn-plain" onClick={() => { handleDelete(index);}}><i className="fa fa-window-close"></i></button></div><br/>
      <div>{content.body}</div>
    </li>
  )
}

export default ContentSectionList;
