import React from "react";
import { Link } from "react-router-dom";
import Cover from "../../../assets/images/cover.png";
import { Settings, formatDate } from "../../../utils/Settings";

function Featured(props) {
  const { events } = props;

  return (
    <>
      <section id="media">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              <div className="section-title text-center">
                <h3>Encounter Church - Media collection</h3>
                <p>
                  <q>
                    Get access to all our live and past sermons, celebration servies, podcasts and more. Watch and share testimonies of what God is doing in people's lives.
                  </q>
                </p>
              </div>
            </div>
          </div>
          <div className="row media-channels">
            <div className="col-4">
              <div className="channel">
                <a href="https://www.youtube.com/@encounterchurchuganda3822"><i className="fab fa-youtube"></i>&nbsp;YOUTUBE</a>
              </div>
            </div>
            <div className="col-4">
              <div className="channel twitter">
                <a href="#"><i className="fab fa-twitter"></i>&nbsp;TWITTER</a>
              </div>
            </div>
            <div className="col-4">
              <div className="channel facebook">
                <a href="https://www.facebook.com/encounterug"><i className="fab fa-facebook"></i>&nbsp;FACEBOOK</a>
              </div>
            </div>
          </div>
          <div className="row blog-home">
            {events.map((category) => {
              return CategoryCard(category);
            })}
          </div>
        </div>
      </section>
    </>
  );
}

function CategoryCard(category) {
  return (
    <div className="col-12 col-lg-4 res-margin" key={category.id}>
      <div className="blog-col">
        <p>
          {category.conferenceUrl ?
            <>
              <iframe
                width="100%"
                src={category.conferenceUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <span className="blog-category">{category.type}</span>
            </> : <>
              <img src={(category.cover != null) ? Settings.getFile(category.cover.path) : Cover} className="blog-img" alt="" />
            </>
          }
        </p>

        <div className="blog-wrapper">
          <div className="blog-text">
            <p className="blog-about">
              <span>{category.location}</span>
              <span>{formatDate(category.startDateTime)}</span>
            </p>

            <h4>
              <a href="#">
                {category.title}
              </a>
            </h4>

            <p>
              {category.details}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Featured;
