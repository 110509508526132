import React from "react";
import { Link } from "react-router-dom";
import UploadAPI from "../../services/UploadAPI";

export default function UploadChunked(props) {
  const { handleSubmit, title = null} = props;
  let token = + new Date();
  let numberOfChunks = 0;
  let chunkIndex = 0;
  let chunks = [];
  let confirmedUpload = 0;
  let uploaded = [];
  let file = null;
  const [progress, setProgress] = React.useState(0);
  const [showprogress, setShowprogress] = React.useState(false);
  const handleResult = (result) => {
    confirmedUpload += uploaded[chunkIndex];
    setProgress(file === null ? 0 : ((confirmedUpload) * 100 / file.size))
    //Update progress state
    if (result.data.id > 0) {
      //The upload has completed
      handleSubmit(result);
    } else {
      chunkIndex += 1;
      upload();
    }
  };

  const config = {
    onUploadProgress: (progressEvent) => {
      uploaded[chunkIndex] = progressEvent.loaded;
    },
  };

  function handleChunckedSelection(event) {
    file = event.target.files[0];
    setShowprogress(true);
    createChunks();
  }

  function upload() {
    UploadAPI.uploadChunked(getFormData(), handleResult, config); 
  }

  function createChunks() {
    let size = 2048000, mChunks = Math.ceil(file.size / size);
    /*console.log(`Number of chunks ${mChunks}`);
    console.log(`Size of file ${file.size}`);*/
    token = + new Date();
    for (let i = 0; i < mChunks; i++) {
        numberOfChunks += 1;
        let c = file.slice(i * size, Math.min(i * size + size, file.size), file.type);
        chunks.push(c);
    }
    upload();
  }

  function getFormData() {
    let formData = new FormData();
    formData.set('chunks', numberOfChunks);
    formData.set('chunk', chunkIndex);
    formData.set('name', file.name);
    formData.set('token', token);
    formData.set('file', chunks[chunkIndex], `${file.name}`);
    return formData;
  }

  return (
    <>
    {title !== null ? (
      <p>{title}</p>
    ) : ("")}
    <div className="row">
      <div className="col-12">
        <input type="file" onChange={handleChunckedSelection} className="form-control" />
      </div>
    </div>
    { showprogress == true ? (
      <div><br/>
        <div className="progress" style={{height: "20px"}}>
          <div className="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar" style={{width: `${Math.round(progress)}%`}} aria-valuenow={`${Math.round(progress)}`} aria-valuemin="0" aria-valuemax="100">{Math.round(progress)}%</div>
        </div>
      </div>
    ): null}
    </>
  );
}
