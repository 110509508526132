import React, { Fragment } from "react";

class Contact extends React.Component {
  render() {
    return (
      <Fragment>
        <div className='contact-form'>
            <h1>Contact Us</h1>
            <small>Question not answered yet? We are here to help!</small>
            <div className='card'>
              <div className='card-body'>
                Plot 31 Ntinda - Kisaasi Road, Ntinda Shopping Complex, Block B&C 3rd Floor, Kampala
                <hr />
                <input
                  type='text'
                  name='name'
                  className='form-control'
                  placeholder='Name'
                />
                <br />
                <div className="custom-row">
                  <div className="custom-col">
                    <div className="phone-number">
                        <div className="code">+256 </div>
                        <div className="phone">
                          <input type="text" placeholder="Your Mobile number" name="phone"/>
                        </div>
                    </div>                    
                  </div>
                  <div className="custom-col email">
                    <input
                    type='email'
                    name='email'
                    className='form-control'
                    placeholder='Email'
                    />
                  </div>
                </div>
                <br />
                <input
                  type='text'
                  name='subject'
                  className='form-control'
                  placeholder='Subject'
                />
                <br />
                <textarea name='message' className='form-control' rows="4" placeholder="Message"></textarea>
                <br/>
                <div className="actions">
                  <button className="tukole-primary">Send Message</button>
                </div>
              </div>
            </div>
        </div>
      </Fragment>
    );
  }
}

export default Contact;
