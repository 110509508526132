import axios from "axios";
import resolveBaseUrl from ".";
import { browserHistory } from 'react-router';

const baseUrl = resolveBaseUrl();

class ServiceAreaAPI {
  static fetch() {
    return axios.get(`${baseUrl}/api/serviceareas`);
  }

  static details(id) {
    return axios.get(`${baseUrl}/api/serviceareas/${id}`);
  }

  static create(record) {
    return axios.post(`${baseUrl}/api/serviceareas`, record );
  }

  static volunteer(record,id) {
    return axios.post(`${baseUrl}/api/serviceareas/${id}`, record );
  }

  static checklistTemplate(record) {
    return axios.post(`${baseUrl}/api/checklisttemplates/${record.serviceArea.id}`, record );
  }

  static checklistTemplateItem(record) {
    console.log(record);
    return axios.post(`${baseUrl}/api/checklisttemplates/${record.checklistTemplate.id}/item`, record );
  }

  static edit(record) {
    browserHistory.push('/pathToRedirect')
    return axios.put(`${baseUrl}/api/serviceareas`, record);
  }

  static delete(id) {
    return axios.delete(`${baseUrl}/api/serviceareas/${id}`);
  }
}

export default ServiceAreaAPI;
