/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import {Route, Link, Routes, useParams} from 'react-router-dom';
import moment from 'moment';
import parse from 'html-react-parser';
import PropTypes from "prop-types";
import Preview from "../common/preview"
import { bindActionCreators } from "redux";
import * as contentActions from "../../redux/actions/contentActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "../Preloader/Preloader";
import { Settings } from "../../utils/Settings";
import { isAdmin } from "../../utils/Authentication";


toast.configure({
  autoClose: 8000,
  draggable: false,
});

class ContentDetails extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.fetchContent(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      
    }
  }

  constructor(props) {
    super(props);
    this.state = { key: "description" };
  }

  handleChange = (event) => {
    
  };

  handleNewClick = (event, content) => {
    event.preventDefault();
  };

  handleSubmit = (event) => {
  };

  closeModal = () => {
    const { actions } = this.props;
    actions.hideModal();
  };

  label = (content) => {    
    let lb = content.title;
  
    if (lb == null || lb.length === 0) {
      if (content.details) {
        lb = content.details;
      } else {
        if (content.uploads) {
          const videos  = content.uploads.filter(u => u.path.endsWith('.mp4'));
          const pdfs  = content.uploads.filter(u => u.path.endsWith('.pdf'));  
          if (videos.length > 0) {
            lb = videos[0].name;
          } else if(pdfs.length > 0) {
            lb = pdfs[0].name;
          }
        }
      }
    }
    
    if (lb == null || lb.length === 0) {
      lb = "";
    }
    return lb;
  };
  
  formatDate = (value) => {
    if (value) {
      return moment(String(value)).format('MMMM Do YYYY, h:mm:ss a')
    }
  };

  render() {
    let {content = null, isLoading = true} = this.props;
    if (content === null) {
      //content = this.props.location.state.content;
    }
    return (
      !isLoading && content ? <Fragment>
        <div className="blue-strip">
          <div className="row">
            <div className="col-10">
              {parse(this.label(content))}
              <div>
                <b></b>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <i className="fa fa-clock"></i> {this.formatDate(content.created)}
              </div>
            </div>
            {isAdmin() ? (
            <div className="col-2">
              <div className="edit-content">
                <Link
                    to={{
                      pathname: `/contents/${content.id}/edit/`,
                      state: {
                        content: content,
                      },
                    }}
                    className="custom-btn d-inline-flex align-items-center m-2 m-sm-0"
                    >
                    <i className="fa fa-edit"></i> Edit
                </Link>
              </div>
            </div>) : <></>}
          </div>
        </div>

        <div className="content-body">
        { content.cover && content.cover.id > 0 ? (
                      <div className="content-cover"><img src={Settings.getFile(content.cover.path)} /></div>
                    ) : null}
          {content.details ? parse(content.details) : ""}
        </div>
        {content.uploads && content.uploads.length > 0 ? (
          <Preview files={content.uploads}/>
        ) : null}
        
      </Fragment> : <Preloader/>
    );
  }
}

ContentDetails.propTypes = {
  actions: PropTypes.object.isRequired,
  content: PropTypes.object,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  contents: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    contents: state.contents.contents,
    error: state.contents.error,
    isLoading: state.contents.isLoading,
    showModal: state.contents.showModal,
    content: state.contents.content,
    response: state.contents.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(contentActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentDetails);
