/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import UserForm from "./User-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure({
  autoClose: 8000,
  draggable: false
});

class RegisterContainerEdit extends React.Component {
  componentDidMount() {
    const { actions, cActions } = this.props;
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response, cActions } = this.props;
      if (response !== null) {
        this.myToast = toast.success(
          `${response.name} has been saved successfully`
        );
      }
    }
  }

  constructor(props) {
    super(props);
    this.state = { user: this.props.location.state.user };
    this.myToast = null;
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    let currentUser = this.state.user;

    if (name === "attachments") {
      currentUser.attachments.append(value);
      console.log(event.target.value)
    } else if (name === "category") {
      currentUser = { ...this.state.user, [name]: { id: value } };
    } else if (name === "serviceProvider") {
      currentUser = { ...this.state.user, [name]: { id: value } };
    } else {
      currentUser = { ...this.state.user, [name]: value };
    }
    this.setState({ user: currentUser });
  };

  handleNewClick = (event, user) => {
    event.preventDefault();
  };

  handleUpdate = event => {
    event.preventDefault();
    const { actions } = this.props;
    actions.updateUser(this.state.user);
  };

  render() {
    const {
      isLoading = true,
      error = "",
    } = this.props;

    const { user } = this.state;

    if (error.length > 0) {
      this.myToast = toast.error(error);
    }

    return (
      <Fragment>
        {this.myToast !== null ? this.myToast : ""}
        <div className="row">
          <div className="col-4">

          </div>
          <div className="col-4">
            <br/><br/>
            <UserForm
              isLoading={isLoading}
              user={user}
              title="Edit User"
              handleNewClick={this.handleNewClick}
              handleSubmit={this.handleUpdate}
              handleChange={this.handleChange}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

RegisterContainerEdit.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    users: state.users.users,
    error: state.users.error,
    isLoading: state.users.isLoading,
    showModal: state.users.showModal,
    user: state.users.user,
    response: state.users.response
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterContainerEdit);
