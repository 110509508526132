/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as contentActions from "../../redux/actions/contentActions";
import * as eventActions from "../../redux/actions/eventActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "../Preloader/Preloader";
import Ministry from "./Ministry";
import Contact from "./Contact";
import Featured from "./Featured";
import Slider from "./Slider";
import About from "./About";
import Giving from "./Giving";
import "./Home.scss";
import "../../assets/css/media.css";
import Blog from "./Blog";
import Team from "./Team";


toast.configure({
  autoClose: 8000,
  draggable: false,
});

class Home extends React.Component {
  componentDidMount() {
    const { actions, eActions } = this.props;
    actions.fetchContents(this.search);
    eActions.fetchEvents({
      types: "",
      title: "",
      startDateTime: "",
      endDateTime: ""
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response } = this.props;
      if (response !== null) {
        this.myToast = toast.success(
          `${response.name} has been saved successfully`
        );
      }
    }
  }

  constructor(props) {
    super(props);

    this.activePage = 1;
    this.search = {
      category: "",
      title: "",
      page: this.activePage
    };

    this.myToast = null;
  }


  render() {
    const {
      contents,
      events = [],
      isLoadingContents = true,
      isLoadingEvents = true,
      error = "",
    } = this.props;

    if (error.length > 0) {
      this.myToast = toast.error(error);
    }

    return (isLoadingContents || isLoadingEvents) ? <Preloader /> : (
      <>
        <Slider contents={contents.filter(record => record.category == "Slider" && record.active)}/>
        <Ministry />
        <Blog contents={contents.filter(record => record.category == "Blog")} />
        <Featured events={events.slice(0,3)} />
        <Giving />
        <Contact />
      </>
    );
  }
}

Home.propTypes = {
  actions: PropTypes.object.isRequired,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingContents: PropTypes.bool.isRequired,
  contents: PropTypes.array.isRequired,
  isLoadingEvents: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    contents: state.contents.contents,
    events: state.events.events,
    totalRecords: state.contents.totalRecords,
    error: state.contents.error,
    isLoading: state.contents.isLoading,
    isLoadingContents: state.contents.isLoadingContents,
    isLoadingEvents: state.events.isLoadingEvents,
    response: state.contents.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(contentActions, dispatch), eActions: bindActionCreators(eventActions, dispatch)  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);