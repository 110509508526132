/* eslint-disable react/forbid-prop-types */
import React from "react";
import LoadingSpinner from "../common/LoadingSpinner";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

export default class LoginForm extends React.Component {
  render() {
    const {
      error,
      isLoading = false,
      handleSubmit,
      handleChange,
      user = { phone: "", password: "" },
    } = this.props;
    const { phone, password } = user;
    let loadingSpinner = "";
    if (isLoading) {
      loadingSpinner = (
        <span>
          <LoadingSpinner />
          <span> </span>
        </span>
      );
    }
    return (
      <div className="card">
        <div className="card-body">
          <div>
            {error && error.length > 0 ? error : ""}
            <div>
              <input
                type="text"
                placeholder="Your Mobile number"
                onChange={handleChange}
                value={phone}
                name="phone"
                className="form-control"
              />
            </div>
            <br />
            <div className="row">
              <div className="col-4">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(event) => {
                    handleSubmit(event, "otp");
                  }}
                >
                  {loadingSpinner} Proceed
                </Button>
              </div>
              <div className="col-8">
                
            <Link
              to="/admin"
              className="btn-secondary-custom"
            >
              &nbsp;Login with Email
            </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
