/* eslint-disable react/forbid-prop-types */
import React from "react";
import OtpInput from "react-otp-input";
import LoadingSpinner from "../common/LoadingSpinner";
import Button from "@material-ui/core/Button";

export default class LoginOtpForm extends React.Component {
  render() {
    const {
      error,
      isLoading = false,
      handleSubmit,
      handleChange,
      user = { phone: "", password: "" },
    } = this.props;

    const onChange = (otp) => {
      handleChange({ target: { name: "password", value: otp } });
    };

    const { phone, password } = user;
    let loadingSpinner = "";
    if (isLoading) {
      loadingSpinner = (
        <span>
          <LoadingSpinner />
          <span> </span>
        </span>
      );
    }
    return (
      <div className="card">
        <div className="card-body">
          <div className="login">
            <b>A one-time password has been sent to your phone.<br/>Please enter it here below.</b><br/>
            {error && error.length > 0 ? error : ""}
            <div className="otp">
              <OtpInput
                value={password}
                onChange={onChange}
                name="password"
                numInputs={5}
                separator={<span>&nbsp;</span>}
              />
            </div><br/>
            <div className="buttons">
              <Button
                variant="contained"
                color="primary"
                onClick={(event) => {
                  handleSubmit(event, "otpLogin");
                }}
              >
                {loadingSpinner} Proceed
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
