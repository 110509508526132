import axios from "axios";
import resolveBaseUrl from ".";

const baseUrl = resolveBaseUrl();

class LittleMemberAPI {
  static fetch(userType) {
    return axios.get(`${baseUrl}/api/children?type=${userType}`);
  }

  static search(searchParams) {
    const {
      name = "",
      phone = "",
      role  = "",
      page = 1
    } = searchParams;
    return axios.get(`${baseUrl}/api/children?role=${role}&name=${name}&phone=${phone}&page=${page}`);
  }

  static createLittleMember(littleMember) {
    return axios.post(`${baseUrl}/api/children`, littleMember);
  }

  static createLittleMemberAttendance(littleMemberAttendance) {
    return axios.post(`${baseUrl}/api/children/${littleMemberAttendance.member.id}`, littleMemberAttendance);
  }

  static updateLittleMember(littleMember) {
    return axios.put(`${baseUrl}/api/children`, littleMember);
  }
}

export default LittleMemberAPI;
