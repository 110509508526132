/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../common/LoadingSpinner";
import { makeStyles } from "@material-ui/core/styles";
import Upload from "../common/Upload";
import UploadChunked from "../common/UploadChunked";
import { Settings, formattedDateTime, convertDate } from "../../utils/Settings";
import DatePicker from "react-datepicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

const mapStyles = {
  width: "100%",
  height: "100%",
};

class EventForm extends React.Component {
  constructor(props) {
    super(props);
  }

  itemCard = (file, handleDelete, index) => {
    return (
      <li className="list-group-item" key={file.id}>
        <button
          onClick={() => {
            handleDelete(index);
          }}
          className="file-item"
        >
          <i className={`fa ${this.getIconName(file.path)}`}></i>{" "}
          {file.name != null ? file.name : file.path}
        </button>
      </li>
    );
  };

  getIconName = (fileName) => {
    let icon = "fa-file-pdf-o";
    if (fileName.endsWith(".mp4")) {
      icon = "fa-play";
    } else if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
      icon = "fa-file-word-o";
    }
    return icon;
  };

  render() {
    const {
      handleSubmit,
      handleChange,
      event,
      isLoading = false,
      handleDelete,
    } = this.props;
    let loadingSpinner = "";
    if (isLoading) {
      loadingSpinner = (
        <span>
          <LoadingSpinner />
          <span> </span>
        </span>
      );
    }
    return (
      <Fragment>
        <br />
        <div className="main-container">
          <div className="  row">
            <div className="col-9">
              <div className="row">
                <div className="col-3">
                  <select
                    name="type"
                    className="form-control"
                    onChange={handleChange}
                    value={event.type}
                  >
                    <option value="">Category</option>
                    {["Public", "Internal", "Invite Only"].map((s) => (
                      <option value={s} key={`t${s}`}>
                        {s}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-9">
                  <input
                    placeholder="Title"
                    onChange={handleChange}
                    name="title"
                    value={event.title}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-3">
                  <DatePicker
                    name="startDateTime"
                    valueName="selected"
                    placeholderText="Start Date &amp; Time"
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    showTimeSelect
                    timeFormat="HH:mm"
                    portalId="root-portal"
                    value={event.startDateTime ? event.startDateTime : ""}
                    selected={event.startDateTime ? convertDate(event.startDateTime) : new Date()}
                    onChange={(date) => {
                      const event = {
                        target: {
                          name: "startDateTime",
                          value: formattedDateTime(date),
                        },
                      };
                      handleChange(event);
                    }}
                  />
                </div>
                <div className="col-3">
                  <DatePicker
                    name="endDateTime"
                    valueName="selected"
                    placeholderText="End Date &amp; Time"
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    showTimeSelect
                    timeFormat="HH:mm"
                    portalId="root-portal"
                    value={event.endDateTime ? event.endDateTime : ""}
                    selected={event.endDateTime ? convertDate(event.endDateTime) : new Date()}
                    onChange={(date) => {
                      const event = {
                        target: {
                          name: "endDateTime",
                          value: formattedDateTime(date),
                        },
                      };
                      handleChange(event);
                    }}
                  />
                </div>
                <div className="col-3">
                  <input
                    placeholder="Location"
                    onChange={handleChange}
                    name="location"
                    value={event.location}
                    className="form-control"
                  />
                </div>
                <div className="col-3">
                  <input
                    placeholder="Remote URL"
                    onChange={handleChange}
                    name="conferenceUrl"
                    value={event.conferenceUrl}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className="tab-events">
                <div className="row">
                  <div className="col-10">
                    <Upload
                      handleSubmit={(response) => {
                        handleChange({
                          target: { name: "cover", value: response.data },
                        });
                      }}
                      multiple={false}
                      title="Cover photo"
                    />
                  </div>
                  <div className="col-2">
                    {event.cover && event.cover.id > 0 ? (
                      <img src={Settings.getFile(event.cover.path)} />
                    ) : null}
                  </div>
                </div>
              </div>
              <br />
              <div className="tab-events">
                <div>
                  <textarea
                    placeholder="Details"
                    rows="5"
                    className="form-control"
                    defaultValue={event.details}
                    onChange={handleChange}
                    name="details"
                  ></textarea>
                </div>
              </div>
              <button className="btn" onClick={handleSubmit}>
                {loadingSpinner}
                Save
              </button>
            </div>
            <div className="col-3">
              {event.uploads && event.uploads.length > 0 ? (
                <ul className="list-group">
                  {event.uploads.map((file, i) => {
                    return this.itemCard(file, handleDelete, i);
                  })}
                </ul>
              ) : null}
              <div className="tab-events">
                <Upload
                  handleSubmit={(response) => {
                    handleChange({
                      target: { name: "uploads", value: response.data },
                    });
                  }}
                  multiple={true}
                  title="Resources (pdf, word, audio, images...)"
                  handleDelete={handleDelete}
                />
              </div>
              <div className="tab-events">
                <UploadChunked
                  handleSubmit={(response) => {
                    handleChange({
                      target: { name: "uploads", value: response.data.upload },
                    });
                  }}
                  title="Videos"
                  handleDelete={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default EventForm;
