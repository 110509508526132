import React from "react";
import { Settings } from "../../../utils/Settings";

export default function ImagePreview(props) {
  const { file } = props;

  return (
    <>
      <div className="download-strip"><a href={Settings.getFile(file.path)}><i className="fa fa-download"></i> DOWNLOAD</a></div>
      <img
        src={Settings.getFile(file.path)}
        width="auto"
        alt={file.name}/>
    </>
  );
}
