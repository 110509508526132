/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as littleMemberActions from "../../redux/actions/littleMemberActions";
import * as userActions from "../../redux/actions/userActions";
import ItemList from "./List-view";
import Form from "./Form";
import AttendanceForm from "./Attendance-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchForm from "./Search-form";
import Modal from "../common/modal/Modal";
import UserForm from "../users/User-form";
import { formattedDateTime } from "../../utils/Settings";

toast.configure({
  autoClose: 8000,
  draggable: false,
});

class LittleMembers extends React.Component {
  componentDidMount() {
    const { actions, cActions, uActions } = this.props;
    actions.searchLittleMembers(this.search);
    uActions.searchUsers({});
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response } = this.props;
      if (response !== null) {
        this.myToast = toast.success(
          `${response.firstname} has been saved successfully`
        );
      }
      actions.searchLittleMembers(this.search);
    }

    if (this.props.attendanceResponse !== prevProps.attendanceResponse) {
      const { actions, attendanceResponse } = this.props;
      if (attendanceResponse !== null) {
        this.myToast = toast.success(
          `Attendance has been recorded successfully`
        );
        this.closeAttendanceModal();
      }
      actions.searchLittleMembers(this.search);
    }

    if (this.props.userResponse !== prevProps.userResponse) {
      const { userResponse, uActions } = this.props;
      if (userResponse !== null) {
        this.myToast = toast.success(
          `${userResponse.name} has been saved successfully`
        );
        const event = {
          target: {
            name: "user",
            value: userResponse.id,
          },
        };
        this.handleChange(event);
        this.closeModal();
      }
      uActions.searchUsers({});
    }
  }

  constructor(props) {
    super(props);
    this.littleMember = { firstname: "", lastname: "", dob: "", user: {} };
    this.state = {
      littleMember: this.littleMember,
      showModal: false,
      showAttendanceModal: false,
      user: { name: "", email: "", phone: "" },
      littleMemberAttendance: {
        arrivalDateTime: formattedDateTime(new Date()),
        departureDateTime: "",
        remark: "",
        user: {},
        member: {},
      },
    };

    this.myToast = null;
    this.activePage = 0;
    this.search = {
      name: "",
      page: this.activePage,
    };
  }

  handleChildrenClick = (record) => {
    this.setState({ showAttendanceModal: true });
    let currentRecord = this.state.littleMemberAttendance;
    currentRecord = { ...this.state.littleMemberAttendance, user: { id: record.user.id }, member: record };
    this.setState({ littleMemberAttendance: currentRecord });
  };

  handleSearchChange = (event) => {
    const { value, name } = event.target;
    this.search[name] = value;
  };

  handleSearch = (event) => {
    const { actions } = this.props;
    actions.searchLittleMembers(this.search);
  };

  handleChange = (event) => {
    const { value, name } = event.target;
    let currentUser = this.state.littleMember;

    if (name === "category") {
      currentUser = { ...this.state.littleMember, [name]: { id: value } };
    } else if (name === "user") {
      currentUser = { ...this.state.littleMember, [name]: { id: value } };
    } else {
      currentUser = { ...this.state.littleMember, [name]: value };
    }
    this.setState({ littleMember: currentUser });
  };

  handleChangeAttendance = (event) => {
    const { value, name } = event.target;
    let currentRecord = this.state.littleMemberAttendance;

    if (name === "user") {
      currentRecord = { ...this.state.littleMemberAttendance, [name]: { id: value } };
    } else {
      currentRecord = { ...this.state.littleMemberAttendance, [name]: value };
    }
    this.setState({ littleMemberAttendance: currentRecord });
  };  

  handleUserChange = (event) => {
    const { value, name } = event.target;
    let currentUser = this.state.user;
    currentUser = { ...this.state.user, [name]: value };
    this.setState({ user: currentUser });
  };

  handleNewClick = (event, littleMember) => {
    event.preventDefault();
  };

  handlePageClick = (pageNumber) => {
    this.activePage = pageNumber;
    this.search.page = pageNumber;
    const { actions } = this.props;
    actions.searchLittleMembers(this.search);
  };

  handleClickRegisterParent = () => {
    this.setState({ showModal: true });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { actions } = this.props;
    actions.createLittleMember(this.state.littleMember);
  };

  handleSubmitAttendance = (event) => {
    event.preventDefault();
    const { actions } = this.props;
    actions.createLittleMemberAttendance(this.state.littleMemberAttendance);
  };

  handleSubmitUser = (event) => {
    event.preventDefault();
    const { uActions } = this.props;
    uActions.createUser(this.state.user);
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  closeAttendanceModal = () => {
    this.setState({ showAttendanceModal: false });
  };

  render() {
    const {
      littleMembers = [],
      users = [],
      isLoading = true,
      totalRecords,
      error = "",
      userError = "",
    } = this.props;

    const {
      littleMember,
      showModal = false,
      user,
      showAttendanceModal = false,
      littleMemberAttendance,
    } = this.state;

    const visibility = showModal ? "visible" : "invisible";

    if (error.length > 0) {
      this.myToast = toast.error(error);
    }

    if (userError.length > 0) {
      this.myToast = toast.error(userError);
    }

    return (
      <Fragment>
        {showModal ? (
          <Modal
            visibility={visibility}
            modalContentId="newOrderItemId"
            modalId="newOrderId"
            closeModal={this.closeModal}
            title="New Parent"
          >
            <UserForm
              user={user}
              handleChange={this.handleUserChange}
              handleSubmit={this.handleSubmitUser}
            />
          </Modal>
        ) : null}
        {showAttendanceModal ? (
          <Modal
            visibility={showAttendanceModal ? "visible" : "invisible"}
            modalContentId="newOrderItemId"
            modalId="newOrderId"
            closeModal={this.closeAttendanceModal}
            title="Record attandance"
          >
            <AttendanceForm
              isLoading={isLoading}
              littleMemberAttendance={littleMemberAttendance}
              users={users}
              title="Attendance"
              handleNewClick={this.handleNewClick}
              handleClickRegisterParent={this.handleClickRegisterParent}
              handleSubmit={this.handleSubmitAttendance}
              handleChange={this.handleChangeAttendance}
            />
          </Modal>
        ) : null}
        <div className="main-content-area">
          <div className="content-header">Children</div>
          <div className="row">
            <div className="col-9">
              <SearchForm
                handleSubmit={this.handleSearch}
                handleChange={this.handleSearchChange}
              />
              <br />
              <ItemList
                littleMembers={littleMembers}
                isLoading={isLoading}
                activePage={this.activePage}
                totalItemsCount={totalRecords}
                handlePageClick={this.handlePageClick}
                handleChildrenClick={this.handleChildrenClick}
              />
            </div>
            <div className="col">
              <Form
                isLoading={isLoading}
                littleMember={littleMember}
                users={users}
                title="Register child"
                handleNewClick={this.handleNewClick}
                handleClickRegisterParent={this.handleClickRegisterParent}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

LittleMembers.propTypes = {
  actions: PropTypes.object.isRequired,
  uActions: PropTypes.object.isRequired,
  littleMember: PropTypes.object,
  user: PropTypes.object,
  littleMemberAttendance: PropTypes.object,
  response: PropTypes.object,
  userResponse: PropTypes.object,
  error: PropTypes.string,
  userError: PropTypes.string,
  attendanceResponse: PropTypes.object,
  attendanceError: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  littleMembers: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
};

function mapStateToProps(state) {
  return {
    littleMembers: state.littleMembers.littleMembers,
    users: state.users.users,
    user: state.users.user,
    error: state.littleMembers.error,
    userError: state.users.error,
    isLoading: state.littleMembers.isLoading,
    showModal: state.littleMembers.showModal,
    littleMember: state.littleMembers.littleMember,
    littleMemberAttendance: state.littleMembers.littleMemberAttendance,
    totalRecords: state.littleMembers.totalRecords,
    response: state.littleMembers.response,
    attendanceError: state.littleMembers.attendanceError,
    userResponse: state.users.response,
    attendanceResponse: state.littleMembers.attendanceResponse,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(littleMemberActions, dispatch),
    uActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LittleMembers);
