/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as eventActions from "../../redux/actions/eventActions";
import ItemList from "./Event-list-view";
import SearchForm from "./Search-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formattedDateTime } from "../../utils/Settings";

toast.configure({
  autoClose: 8000,
  draggable: false,
});

class Events extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.fetchEvents(this.state.search);
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response } = this.props;
      if (response !== null) {
        this.myToast = toast.success(
          `${response.name} has been saved successfully`
        );
      }
    }
  }

  constructor(props) {
    super(props);
    this.event = {
      category: "",
      active: true,
      position: "",
      title: "",
      details: "",
      attachments: [],
    };

    this.activePage = 1;
    this.state = {
      search: {
        types: "",
        title: "",
        startDateTime: formattedDateTime(new Date()),
        endDateTime: formattedDateTime(new Date()),
        page: this.activePage
      }
    }

    this.myToast = null;
  }

  handleSearchChange = (e) => {
    const { value, name } = e.target;
    let currentSearch = this.state.search;

    currentSearch = { ...this.state.search, [name]: value };
    this.setState({ search: currentSearch });  
  };

  handleNewClick = (e, event) => {
    e.preventDefault();
  };

  handleSearch = (event) => {
    const { actions } = this.props;
    actions.fetchEvents(this.state.search);
  };

  handlePageClick = (pageNumber) => {
    this.activePage = pageNumber;
    this.search.page = pageNumber;
    const { actions } = this.props;
    actions.fetchEvents(this.search);
  };

  closeModal = () => {
    const { actions } = this.props;
    actions.hideModal();
  };

  render() {
    const {
      events,
      subjects,
      forms,
      totalRecords,
      users,
      isLoadingEvents = true,
      showModal = true,
      error = "",
      event = {
        category: "",
        title: ""
      },
    } = this.props;
    
    const visibility = showModal ? "invisible" : "invisible";

    if (error.length > 0) {
      this.myToast = toast.error(error);
    }

    return (

      <Fragment>
        <div className="blue-strip">
          <div className="row">
            <div className="col-10">
              <SearchForm
                event={this.state.search}
                handleNewClick={this.handleNewClick}
                handleSubmit={this.handleSearch}
                handleChange={this.handleSearchChange}
              />
            </div>
            <div className="col-2">
              <div className="edit-event">
                <Link
                    to={{
                      pathname: "/events/new/"
                    }}
                    className="custom-btn d-inline-flex align-items-center m-2 m-sm-0"
                    >
                    <i className="fa fa-plus"></i> New
                </Link>
              </div>              
            </div>
          </div>
        </div>
        <div className="event-list"  style={{zIndex:100}}>
          <ItemList events={events} isLoading={isLoadingEvents} categories={subjects} handlePageClick={this.handlePageClick} activePage={this.activePage} totalItemsCount={totalRecords}/>
        </div>
      </Fragment>
    );
  }
}

Events.propTypes = {
  actions: PropTypes.object.isRequired,
  event: PropTypes.object,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingEvents: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired,
  subjects: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    events: state.events.events,
    totalRecords: state.events.totalRecords,
    subjects: state.events.subjects,
    forms: state.events.forms,
    users: state.events.users,
    error: state.events.error,
    isLoadingEvents: state.events.isLoadingEvents,
    isLoading: state.events.isLoading,
    showModal: state.events.showModal,
    event: state.events.event,
    response: state.events.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(eventActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);
