/* eslint-disable react/forbid-prop-types */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import LoginForm from "./AdminLogin-view";
import { Redirect } from "react-router-dom";
import {
  processLoginResponse,
  isAuth,
  processOtpLoginResponse,
} from "../../utils/Authentication";

class AdminLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        username: "",
        password: "",
      },
      error: "",
    };
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    const user = { ...this.state.user, [name]: value };
    this.setState({ user });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { user } = this.state;
    const error = this.validateInputs(user);
    const { actions } = this.props;
    if (error.length > 0) {
      actions.validationError(error);
    } else {
      actions.loginUser(user);
    }
  };

  validateInputs = (user) => {
    let error = "";
    if (user.username.length < 3) {
      error = "Invalid username";
    }
    return error;
  };

  render() {
    const { error, isLoading = false, response = null } = this.props;
    const { user } = this.state;
    if (response != null) {
      processOtpLoginResponse(response);
    }

    if (isAuth()) {
      document.dispatchEvent(new CustomEvent("login", { detail: true }));
    }

    return isAuth() ? (
      <Redirect to="/" />
    ) : (
      <div className="container">
        <br/>
        <div className="row">
          <div className="col-3">
            <div className="section-title text-center text-lg-left">
              
            </div>
          </div>
          <div className="col-6">
            <h3>Login here</h3>
            <LoginForm
              error={error}
              isLoading={isLoading}
              handleChange={this.handleChange}
              handleSubmit={this.handleSubmit}
              validateInputs={this.validateInputs}
              user={user}
            />
          </div>
        </div>
      </div>
    );
  }
}

AdminLogin.propTypes = {
  actions: PropTypes.object.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  showOtpScreen: PropTypes.bool.isRequired,
  response: PropTypes.object,
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.users.user,
    error: state.users.error,
    isLoading: state.users.isLoading,
    showOtpScreen: state.users.showOtpScreen,
    response: state.users.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
