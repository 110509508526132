import { browserHistory } from 'react-router';
import { put, takeLatest, call } from "redux-saga/effects";
import { GET_CONTENTS, CREATE_CONTENT, GET_CONTENTS_DETAILS, UPDATE_CONTENT } from "../actions/actionTypes";
import ContentAPI from "../../services/ContentAPI";
import {
  fetchContentsSuccess,
  fetchContentsFailure,
  createContentSuccess,
  createContentFailure,
  updateContentSuccess,
  updateContentFailure,
  fetchContentDetailsSuccess,
  fetchContentDetailsFailure
} from "../actions/contentActions";
import apiErrorHandler from "../../services/apiErrorHandler";

export function* fetchContentsAsync(action) {
  try {
    const response = yield call(ContentAPI.fetch, action.searchParams);
    yield put(fetchContentsSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(fetchContentsFailure(errorMessage));
  }
}

export function* fetchContentDetailsAsync(action) {
  try {
    const response = yield call(ContentAPI.fetchContent, action.contentId);
    yield put(fetchContentDetailsSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(fetchContentDetailsFailure(errorMessage));
  }
}

export function* createContentAsync(action) {
  try {
    const response = yield call(ContentAPI.createContent, action.content);
    yield put(createContentSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(createContentFailure(errorMessage));
  }
}

export function* updateContentAsync(action) {
  try {
    const response = yield call(ContentAPI.editContent, action.content);
    yield put(updateContentSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(updateContentFailure(errorMessage));
  }
}

export function* watchContents() {
  yield takeLatest(GET_CONTENTS, fetchContentsAsync);
}

export function* watchContentDetails() {
  yield takeLatest(GET_CONTENTS_DETAILS, fetchContentDetailsAsync);
}

export function* watchCreateContent() {
  yield takeLatest(CREATE_CONTENT, createContentAsync);
}

export function* watchUpdateContent() {
  yield takeLatest(UPDATE_CONTENT, updateContentAsync);
}
