/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as allActions from "../../redux/actions/serviceAreaActions";
import List from "./List";
import Form from "./Form";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";

toast.configure({
  autoClose: 8000,
  draggable: false
});

class Container extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.fetchServiceAreas();
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response } = this.props;
      if (response !== null) {
        this.myToast = toast.success(
          `${response.name} has been saved successfully`
        );
      }
      actions.fetchServiceAreas();
    }
  }

  constructor(props) {
    super(props);
    this.record = { name: "", details: "" };
    this.myToast = null;
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.record[name] = value;
  };

  handleNewClick = (event, record) => {
    event.preventDefault();
  };

  handleSubmit = event => {
    event.preventDefault();
    const { actions } = this.props;
    actions.createServiceArea(this.record);
  };

  render() {
    const {
      records,
      isLoading = true,
      error = "",
      record = { name: "", details: "" }
    } = this.props;

    if (error.length > 0) {
      this.myToast = toast.error(error);
    }

    return (
      <div className="container content-area">
        <div className="content-header">Service Areas</div>
        <Form
          isLoading={isLoading}
          record={record}
          handleNewClick={this.handleNewClick}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}/>

        <List records={records} isLoading={isLoading} />
      </div>
    );
  }
}

Container.propTypes = {
  actions: PropTypes.object.isRequired,
  record: PropTypes.object,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  records: PropTypes.array.isRequired
};

function mapStateToProps(state) {
  return {
    records: state.serviceAreas.serviceAreas,
    error: state.serviceAreas.error,
    isLoading: state.serviceAreas.isLoading,
    showModal: state.serviceAreas.showModal,
    record: state.serviceAreas.serviceArea,
    response: state.serviceAreas.response
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(allActions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Container);
