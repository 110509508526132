import axios from "axios";
import resolveBaseUrl from ".";
import { browserHistory } from 'react-router';

const baseUrl = resolveBaseUrl();

class ContentAPI {
  static fetch(searchParams) {
    const {
      title= "",
      category= "",
      page = 1
    } = searchParams;
    return axios.get(`${baseUrl}/api/contents?page=${page}&title=${title}&category=${category}`);
  }

  static fetchContent(id) {
    return axios.get(`${baseUrl}/api/contents/${id}`);
  }

  static createContent(content) {
    return axios.post(`${baseUrl}/api/contents`, content );
  }

  static editContent(content) {
    console.log(content);
    browserHistory.push('/pathToRedirect')
    return axios.put(`${baseUrl}/api/contents`, content);
  }

  static deleteContent(id) {
    return axios.delete(`${baseUrl}/api/content/${id}`);
  }
}

export default ContentAPI;
