import React from "react";
import { Link } from "react-router-dom";
import Avatar from "../../assets/images/avatar.png";

function Contact(props) {
  return (
    <section id="contact">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6">
            <div className="section-title text-center">
              <h3>Get In Touch</h3>
              <p>You can connect to us through a number of channels</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="contact-info col-12 col-lg-4 res-margin">
            <h5>
              <span className="icon icon-basic-geolocalize-05"></span>
              Church &amp; Office Location
            </h5>
            <p>
              Natete Business centre
              <br />
              Natete, Kampala Uganda
            </p>

            <h5>
              <span className="icon icon-basic-smartphone"></span>
              Phone Number
            </h5>
            <p>
              <a href="tel:+256782385264">+256 78 238 5264</a>
            </p>

            <h5>
              <span className="icon icon-basic-mail"></span>
              Email Address
            </h5>
            <p>
              <a href="mailto:info@encounterchurchug.com">
                info@encounterchurchug.com
              </a>
            </p>

            <h5>
              <span className="icon icon-basic-clock"></span>
              Service times
            </h5>
            <p>
              Every sunday, 09:00AM to 11:00AM
              <br />
              Every Friday, 06:30PM to 09:30PM
            </p>

            <h5>
              <span className="icon icon-basic-clock"></span>
              Working Hours
            </h5>
            <p>09:00AM to 05:00PM</p>
          </div>
          <div className="col-12 col-lg-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127671.81950931219!2d32.51099723599726!3d0.34886883933883067!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177da368b751a07d%3A0xc5332de107eff179!2sNateete%20Business%20Centre!5e0!3m2!1sen!2sug!4v1663952393637!5m2!1sen!2sug"
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
