import React from "react";
import Avatar from "../../assets/images/avatar.png";
import MtnLogo from "../../assets/images/mtn.png";
import AirtelLogo from "../../assets/images/airtel.png";
import { Link } from "react-router-dom";

function Giving(props) {
  return (
    <section id="giving" className="bg-grey">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-6">
            <div className="section-title text-center">
              <h3>Support the Ministry</h3>
              <p>
                You can partner with God by giving towards the work He is doing through Encounter Church.
              </p>
            </div>
          </div>
        </div>

        <div className="row align-items-center pricing-plans" id="pricing-packages">
          <div className="col-12 col-lg-4 res-margin">
            <div className="price-table">
              <center>
                <img src={MtnLogo} />
              </center>
              <hr/>
              <h2 className="plan-price">
                *165*3#
              </h2>
              <h5>
                <b>CODE:</b> 639376
              </h5>
            </div>
          </div>
          <div className="col-12 col-lg-4 res-margin">
            <div className="price-table plan-popular mb-4 mb-sm-5 mb-lg-0">
              <div><i className="fa fa-university fa-5x"></i></div><br/>
              <h3 className="plan-type">Bank</h3>
              <hr/>
              <div>
                <b>Bank:</b> STANBIC BANK (U) LTD<br/>
                <b>BRANCH:</b> Garden City<br/>
                <b>SWIFT CODE:</b> SBICUGKX<br/>
                <b>SORT CODE:</b> 040147<br/>
              </div>
              <hr/>
              <div>
                <b>A/C NAME:</b> ENCOUNTER CHURCH LIMITED<br/>
                <b>A/C (UGX):</b> 9030020491239<br/>
                <b>A/C (USD):</b> 9030020491263<br/>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <div className="price-table">
              <center>
                <img src={AirtelLogo} />
              </center>
              <hr/>
              <h2 className="plan-price">
                *185*9#
              </h2>
              <h5>
                <b>CODE:</b> 1431444
              </h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="col-12 col-lg-8 offset-lg-2 mobile-phone wow fadeInUp"
            data-wow-offset="10"
            data-wow-duration="1s"
            data-wow-delay="0.9s"
          >
            <img src="images/mobile-phone.png" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Giving;
