/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Preloader from "../Preloader/Preloader";
import LoadingSpinner from "../common/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

class VolunteerForm extends React.Component {
  render() {
    const classes = useStyles;
    const { handleSubmit, handleChange, record, isLoading = false } = this.props;
    return (
      <Fragment>
        <form className={classes.root} noValidate autoComplete="off">
          <div className="row">
            <div className="col-6">
              <Input
                placeholder="Name"
                inputProps={{ "aria-label": "description" }}
                className="form-control"
                onChange={handleChange}
                value={record.name}
                name="name"
              />
            </div>
            <div className="col-6">
              <Input
                placeholder="Phone"
                inputProps={{ "aria-label": "description" }}
                className="form-control"
                onChange={handleChange}
                value={record.phone}
                name="phone"
              />
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-6">
              <Input
                placeholder="Email"
                inputProps={{ "aria-label": "description" }}
                className="form-control"
                onChange={handleChange}
                value={record.email}
                name="email"
              />
            </div>
            <div className="col-3">
              <select
                name="category"
                className="form-control"
                onChange={handleChange}
                value={record.category}
              >
                <option value="">Category</option>
                {["Member", "Leader"].map((s) => (
                  <option value={s} key={`t${s}`}>
                    {s}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-3">
              <Button
                variant="contained"
                color="primary"
                onClick={(event) => handleSubmit(event, this.record)}
              >
                {isLoading ? <LoadingSpinner /> : <></>} Save
              </Button>
            </div>
          </div>
        </form>
        <p>&nbsp;</p>
      </Fragment>
    );
  }
}

export default VolunteerForm;
