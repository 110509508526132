import { combineReducers } from "redux";
import users from "./userReducer";
import contents from "./contentReducer";
import serviceAreas from "./serviceAreaReducer";
import events from "./eventReducer";
import littleMembers from "./littleMemberReducer"

const rootReducer = combineReducers({
  users,
  contents,
  serviceAreas,
  events,
  littleMembers
});

export default rootReducer;
