/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles(theme => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200
    }
  }
}));

class Form extends React.Component {
  render() {
    const classes = useStyles;
    const { handleSubmit, handleChange } = this.props;
    return (
      <Fragment>
        <form className={classes.root} noValidate autoComplete='off'>
          <div className='row'>
            <div className='col-5'>
              <Input
                placeholder='Name'
                inputProps={{ "aria-label": "description" }}
                className='form-control'
                onChange={handleChange}
                name='name'
              />
            </div>
            <div className='col-5'>
              <Input
                placeholder='Details'
                inputProps={{ "aria-label": "description" }}
                className='form-control'
                onChange={handleChange}
                name='details'
              />
            </div>
            <Button
              variant='contained'
              color='primary'
              onClick={event => handleSubmit(event, this.record)}
            >
              Save
            </Button>
          </div>
        </form>
        <p>&nbsp;</p>
      </Fragment>
    );
  }
}

export default Form;
