import React from "react";
import ReactHlsPlayer from "react-hls-player";
import Media from "./Media";
import PdfPreview from "./PdfPreview";
import ImagePreview from "./ImagePreview";

export default function Preview(props) {
  const { files } = props;
  const [file, setFile] = React.useState(files[0]);
  const [progress, setProgress] = React.useState(0);

  function handleClick(file) {
    setFile(file);
  }
  console.log(files);
  return (
    <div className="content-preview">
      <div className="row">
        <div className="col-9">
          {getFileType(file.path) == "media" ? (
            <Media file={file} />
          ) : getFileType(file.path) == "image" ? (
            <ImagePreview file={file} />
          ) : (
            <PdfPreview file={file} />
          )}
        </div>
        <div className="col-3">
          <ul className="list-group">
            {files.map((file) => {
              return ItemCard(file, handleClick);
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}

function getIconName(fileName) {
  let icon = "fa-file-pdf-o";
  if (fileName.endsWith(".mp4")) {
    icon = "fa-play";
  } else if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
    icon = "fa-file-wrod-o";
  }
  return icon;
}

function getFileType(fileName) {
  let icon = "pdf";
  if (
    fileName.endsWith(".mp4") ||
    fileName.endsWith(".mp3") ||
    fileName.endsWith(".wav")
  ) {
    icon = "media";
  } else if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
    icon = "word";
  } else if (
    fileName.endsWith(".jpg") ||
    fileName.endsWith(".jpeg") ||
    fileName.endsWith(".png")
  ) {
    icon = "image";
  }
  return icon;
}

function ItemCard(file, handleClick) {
  return (
    <li className="list-group-item" key={file.id}>
      <button
        onClick={() => {
          handleClick(file);
        }}
        className="file-item"
      >
        <i className={`fa ${getIconName(file.path)}`}></i>{" "}
        {file.name != null ? file.name : file.path}
      </button>
    </li>
  );
}
