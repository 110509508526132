import * as types from "../actions/actionTypes";

const initialState = {
  isLoadingContents: false,
  isLoading: false,
  error: "",
  contents: [],
  totalRecords: 0,
  subjects: [],
  forms: [],
  users: [],
  content: null,
  showModal: false,
  response: null
};

export default function contentReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_CONTENTS:
      return { ...state, isLoadingContents: true };
    case types.GET_CONTENTS_ERROR:
      return { ...state, error: action.error, isLoadingContents: false };
    case types.GET_CONTENTS_SUCCESS:
      return {
        ...state,
        contents: action.contents,
        totalRecords: action.totalRecords,
        isLoadingContents: false
      };
    case types.GET_CONTENTS_DETAILS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.GET_CONTENTS_DETAILS_SUCCESS:
      return {
        ...state,
        content: action.content,
        isLoading: false
      };
    case types.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.users,
        isLoading: false
      };
    case types.CREATE_CONTENT:
      return { ...state, isLoading: true };
    case types.GET_CONTENTS_DETAILS:
      return { ...state, isLoading: true };
    case types.CREATE_CONTENTS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.CLOSE_MODAL:
      return { ...state, showModal: false };
    case types.CREATE_CONTENTS_SUCCESS:
      return {
        ...state,
        response: action.response,
        isLoading: false,
        showModal: false,
        error: ""
      };
      case types.UPDATE_CONTENT:
        return { ...state, isLoading: true };
      case types.GET_CONTENTS_DETAILS:
        return { ...state, isLoading: true };
      case types.UPDATE_CONTENTS_ERROR:
        return { ...state, error: action.error, isLoading: false };
      case types.CLOSE_MODAL:
        return { ...state, showModal: false };
      case types.SHOW_MODAL:
        return { ...state, showModal: true };
      case types.UPDATE_CONTENTS_SUCCESS:
        return {
          ...state,
          response: action.response,
          isLoading: false,
          showModal: false,
          error: ""
        };
    default:
      return state;
  }
}
