import React from "react";
import { Settings } from "../../../utils/Settings";

export default function PdfPreview(props) {
  const { file } = props;

  return (
    <>
      <div className="download-strip"><a href={Settings.getFile(file.path)}><i className="fa fa-download"></i> DOWNLOAD</a></div>
      <embed
        src={Settings.getFile(file.path)}
        width="100%"
        height="600"
        alt="pdf"
        pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"
      ></embed>
    </>
  );
}
