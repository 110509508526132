import * as types from "./actionTypes";

export function fetchContent(contentId) {
  return { type: types.GET_CONTENTS_DETAILS, contentId };
}

export const fetchContentDetailsSuccess = (response) => ({
  type: types.GET_CONTENTS_DETAILS_SUCCESS,
  content: response,
});

export const fetchContentDetailsFailure = (error) => ({
  type: types.GET_CONTENTS_DETAILS_ERROR,
  error,
});

export function fetchContents(searchParams) {
  return { type: types.GET_CONTENTS, searchParams };
}

export function validationError(error) {
  return { type: types.ERROR, error };
}

export function fetchContentsSuccess(response) {
  return {
    type: types.GET_CONTENTS_SUCCESS,
    contents: response,
    totalRecords: response.length,
  };
}

export const fetchContentsFailure = (error) => ({
  type: types.GET_CONTENTS_ERROR,
  error,
});

export function createContent(content) {
  return { type: types.CREATE_CONTENT, content };
}

export const createContentSuccess = (response) => ({
  type: types.CREATE_CONTENTS_SUCCESS,
  response: response,
});

export const createContentFailure = (error) => ({
  type: types.CREATE_CONTENTS_ERROR,
  error,
});

export function updateContent(content) {
  return { type: types.UPDATE_CONTENT, content };
}

export const updateContentSuccess = (response) => ({
  type: types.UPDATE_CONTENTS_SUCCESS,
  response: response,
});

export const updateContentFailure = (error) => ({
  type: types.UPDATE_CONTENTS_ERROR,
  error,
});

export const hideModal = () => ({
  type: types.CLOSE_MODAL,
});
