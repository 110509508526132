/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-toastify/dist/ReactToastify.css";
import parse from 'html-react-parser';
import { Settings } from "../../utils/Settings";

function Blog(props) {
  const {contents = []} = props;
  return (
    <section id="slider" className="bg-grey">
      <div className="container">
	  <div className="categories-wrapper">
				<div className="row d-flex align-items-center">
					{contents.map(record => {
						return (
						<div className="col-12 col-md-4 col-lg-4" key={record.id}> 	
							<ul className="features-item">
								<li> 
									<div className="feature-cover">
										<img src={Settings.getFile(record.cover.path)} />
									</div>
									<div className="feature-box media">
										<div className="box-text media-body align-self-center align-self-md-start">
											<h4>{record.title}</h4>
											<p>
											{record.details ? parse(record.details) : ""}
											</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
						)
					})}
				</div>
			</div>       
      </div>
    </section>
  );
}

export default Blog;

