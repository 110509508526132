import React from "react";
import Link1 from "@material-ui/core/Link";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo_white.png";
import pastors from "../../assets/images/covers/pastors.png";

function Copyright() {
  return (
    <footer>
      <div className="footer-widgets">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3 res-margin">
              <div className="widget">
                <p className="footer-logo">
                  <img src={logo} alt="Encounter Church" data-rjs="2" />
                </p>
                <p>
                  We encounter Jesus through the power of the Holy Spirit and
                  have dominion.
                </p>
                <div className="footer-social">
                  <a href="#" title="Twitter">
                    <i className="fab fa-twitter fa-fw"></i>
                  </a>
                  <a href="#" title="Facebook">
                    <i className="fab fa-facebook-f fa-fw"></i>
                  </a>
                  <a href="#" title="Instagram">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#" title="Dribbble">
                    <i className="fab fa-dribbble"></i>
                  </a>
                  <a href="#" title="Pinterest">
                    <i className="fab fa-pinterest fa-fw"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 offset-lg-1 res-margin">
              <div className="widget">
                <h6>We need volunteers</h6>
                <ul className="footer-menu">
                  <li>- Worship and creative arts</li>
                  <li>- Children's church</li>
                  <li>- Prayer and counseling</li>
                  <li>- Ushering and parking</li>
                </ul>
                <br/>
                Get in touch with any one of our volunteers after service
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3 offset-lg-1 res-margin">
              <div className="widget">
                <h6>Encounter church property</h6>
                <div>
                  We are in the process of identifying and acquiring land that will host a permanent home for Encounter church. For this cause, we request you to prayerfully consider supporting the ministry with a financial gift of any size towards making this a reality.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p className="copyright text-center">
                Copyright © 2022{" "}
                <a href="#" target="_blank">
                  ENCOUNTER CHURCH
                </a>
                . All Rights Reserved.
                {" | "}
                &nbsp;&nbsp;
                <a
                  href="/login"
                  className="d-inline-flex align-items-center m-2 m-sm-0 mr-sm-3"
                >
                  Member Login
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Copyright;
