/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../common/LoadingSpinner";
import { Editor } from "@tinymce/tinymce-react";


class ContentSection extends React.Component {
  constructor(props) {
    super(props);
  }

  itemCard = (file, handleDelete, index) => {
    return (
      <li className="list-group-item" key={file.id}>
        <button
          onClick={() => {
            handleDelete(index);
          }}
          className="file-item"
        >
          <i className={`fa ${this.getIconName(file.path)}`}></i>{" "}
          {file.name != null ? file.name : file.path}
        </button>
      </li>
    );
  };

  getIconName = (fileName) => {
    let icon = "fa-file-pdf-o";
    if (fileName.endsWith(".mp4")) {
      icon = "fa-play";
    } else if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
      icon = "fa-file-wrod-o";
    }
    return icon;
  };

  render() {
    const { handleSubmit, handleChange, content, isLoading = false } = this.props;
    let loadingSpinner = "";
    if (isLoading) {
      loadingSpinner = (
        <span>
          <LoadingSpinner />
          <span> </span>
        </span>
      );
    }
    return (
      <Fragment>
        <br/>
        <div className="main-container">
          <div className="  row">
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <select
                    name="category"
                    className="form-control"
                    onChange={handleChange}
                    value={content.category}
                  >
                    <option value="">Category</option>
                    {["H1","H2","H3"].map((r) => (
                      <option value={r} key={`k${r}`}>
                        {r}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-6">
                  <input
                    placeholder="Position"
                    onChange={handleChange}
                    name="position"
                    value={content.position}
                    className="form-control"
                  />
                </div>
              </div>
              <br/>
              <div className="row">
                <div className="col-12">
                  <input
                    placeholder="Title"
                    onChange={handleChange}
                    name="heading"
                    value={content.heading}
                    className="form-control"
                  />
                </div>
              </div><br/>
              <div className="tab-contents">
                <div>
                <Editor
                    tinymceScriptSrc={
                      process.env.PUBLIC_URL +
                      "/tinymce/js/tinymce/tinymce.min.js"
                    }
                    
                    init={{
                      height: 300,
                      menubar: false,
                      contextmenu: false,
                      branding: false, // Disable "Powered by Tiny" brandin
                    }}

                    plugins = "link image table"
                    toolbar = 'undo redo styles bold italic alignleft aligncenter alignright alignjustify fontsize blockquote | table | bullist numlist outdent indent link image'
                    quickbars_insert_toolbar = {true}
                    onEditorChange={(c) => {
                      const event = { target: { name: "body", value: c } };
                      handleChange(event);
                    }}
                  />
                </div>
              </div>
              <br />
              <button
                className="btn"
                onClick={handleSubmit}
              >
                {loadingSpinner}
                Save
              </button>
            </div>
          </div>
        </div>
      </Fragment>
    )
  }
}
export default ContentSection;
