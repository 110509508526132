/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedRoles: [] };
  }

  
  render() {
    const classes = useStyles;
    const { handleSubmit, handleChange, content } = this.props;
    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <form className={classes.root} noValidate autoComplete="off">
              <div className="row">
                <div className="col-6">
                  <input
                    placeholder="Title"
                    className="form-control"
                    onChange={handleChange}
                    name="title"
                  />
                </div>
                <div className="col-2">
                  <select
                      name="category"
                      className="form-control"
                      onChange={handleChange}
                    >
                    <option value="">Category</option>
                      {["Page","Blog","Resource", "Slider", "Devotional", "Update"].map((s) => (
                        <option value={s} key={`t${s}`}>
                          {s}
                        </option>
                        ))}
                  </select>
                </div>
                <div className="col-1">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => handleSubmit(event)}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SearchForm;
