import * as types from "../actions/actionTypes";

const initialState = {
  isLoading: true,
  error: "",
  serviceAreas: [],
  serviceArea: null,
  showModal: false,
  response: null,
  volunteer: {
    name: "",
    phone: "",
    email: "",
    category: "Member",
  },
};

export default function serviceAreaReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_SERVICEAREAS:
      return { ...state, isLoading: true };
    case types.GET_SERVICEAREAS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.GET_SERVICEAREAS_SUCCESS:
      return {
        ...state,
        serviceAreas: action.serviceAreas,
        isLoading: false,
      };
    case types.CREATE_SERVICEAREA:
      return { ...state, isLoading: true };
    case types.GET_SERVICEAREAS_DETAILS:
      return { ...state, isLoading: true };
    case types.GET_SERVICEAREAS_DETAILS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.GET_SERVICEAREAS_DETAILS_SUCCESS:
      return {
        ...state,
        serviceArea: action.serviceArea,
        isLoading: false,
      };
    case types.CREATE_SERVICEAREAS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.CREATE_SERVICEAREAS_SUCCESS:
      return {
        ...state,
        response: action.response,
        isLoading: false,
        showModal: false,
        error: "",
      };
    case types.CREATE_VOLUNTEER:
      return { ...state, isLoading: true };
    case types.CREATE_VOLUNTEER_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.CREATE_VOLUNTEER_SUCCESS:
      return {
        ...state,
        response: action.response,
        isLoading: false,
        showModal: false,
        error: "",
      };
    case types.CREATE_CHECKLISTTEMPLATE:
      return { ...state, isLoading: true };
    case types.CREATE_CHECKLISTTEMPLATES_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.CREATE_CHECKLISTTEMPLATES_SUCCESS:
      return {
        ...state,
        response: action.response,
        isLoading: false,
        showModal: false,
        error: "",
      };
    case types.CREATE_CHECKLISTTEMPLATE_ITEM:
      return { ...state, isLoading: true };
    case types.CREATE_CHECKLISTTEMPLATE_ITEMS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.CREATE_CHECKLISTTEMPLATE_ITEMS_SUCCESS:
      return {
        ...state,
        response: action.response,
        isLoading: false,
        showModal: false,
        error: "",
      };
    default:
      return state;
  }
}
