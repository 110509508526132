import { all } from "redux-saga/effects";
import { watchLogin, watchFetchOtp, watchLoginOtp } from "./loginSaga";
import {
  watchContents,
  watchCreateContent,
  watchContentDetails,
  watchUpdateContent,
} from "./contentSaga";

import {
  watchUsers,
  watchNewUser,
  watchUpdateUser,
  watchSearchUsers,
  watchSignup,
} from "./userSaga";

import {
  watchServiceAreas,
  watchCreateServiceArea,
  watchServiceAreaDetails,
  watchCreateVolunteer,
  watchCreateChecklistTemplate,
  watchCreateChecklistTemplateItem,
} from "./serviceAreaSaga";

import {
  watchEvents,
  watchCreateEvent,
  watchEventDetails,
  watchUpdateEvent,
} from "./eventSaga";

import {
  watchUpdateLittleMember,
  watchSearchLittleMembers,
  watchLittleMembers,
  watchNewLittleMember,
  watchNewLittleMemberAttendance,
} from "./littleMemberSaga";

function* rootSaga() {
  yield all([
    watchLogin(),
    watchContents(),
    watchCreateContent(),
    watchUpdateContent(),

    watchFetchOtp(),
    watchLoginOtp(),
    watchUsers(),
    watchNewUser(),
    watchUpdateUser(),
    watchContentDetails(),
    watchSearchUsers(),

    watchSignup(),
    watchServiceAreas(),
    watchCreateServiceArea(),
    watchServiceAreaDetails(),
    watchCreateVolunteer(),
    watchEvents(),
    watchCreateEvent(),
    watchUpdateEvent(),
    watchEventDetails(),

    watchLittleMembers(),
    watchNewLittleMember(),
    watchSearchLittleMembers(),
    watchUpdateLittleMember(),
    watchNewLittleMemberAttendance(),
    watchCreateChecklistTemplate(),
    watchCreateChecklistTemplateItem()
  ]);
}

export default rootSaga;
