import * as types from "../actions/actionTypes";

const initialState = {
  isLoading: false,
  response: null,
  attendanceResponse: null,
  error: "",
  attendanceError: "",
  showModal: false,
  showOtpScreen: false,
  totalRecords: 0,
  littleMembers: [],
  categories: [],
  littleMember: { firstname: "", lastname: "", dob: "", user: {} },
  littleMemberAttendance: {arrivalDateTime: "", departureDateTime: "", remark: "", user: {}, member: {}}
};

export default function littleMemberReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LITTLEMEMBERS:
      return { ...state, isLoading: true };
    case types.SEARCH_LITTLEMEMBERS:
      return { ...state, isLoading: true };
    case types.GET_LITTLEMEMBERS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.GET_LITTLEMEMBERS_SUCCESS:
      return {
        ...state,
        littleMembers: action.littleMembers,
        isLoading: false,
      };
    case types.SEARCH_LITTLEMEMBERS_SUCCESS:
      return {
        ...state,
        littleMembers: action.littleMembers,
        totalRecords: action.totalRecords
          ? action.totalRecords
          : action.littleMembers
          ? action.littleMembers.length
          : 0,
        isLoading: false,
      };
    case types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.categories,
        isLoading: false,
      };
    case types.CREATE_LITTLEMEMBER:
      return { ...state, littleMember: action.littleMember };
    case types.CREATE_LITTLEMEMBERS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.CREATE_LITTLEMEMBERS_SUCCESS:
      return {
        ...state,
        response: action.response,
        isLoading: false,
        showModal: false,
        error: "",
      };
    case types.UPDATE_LITTLEMEMBER:
      return { ...state, isLoading: true };
    case types.UPDATE_LITTLEMEMBERS_ERROR:
      return { ...state, error: action.error, isLoading: false };
    case types.UPDATE_LITTLEMEMBERS_SUCCESS:
      return {
        ...state,
        response: action.response,
        isLoading: false,
        showModal: false,
        error: "",
      };
    case types.CREATE_LITTLEMEMBERATTENDANCE:
      return { ...state, littleMemberAttendance: action.littleMemberAttendance };
    case types.CREATE_LITTLEMEMBERATTENDANCES_ERROR:
      return { ...state, attendanceError: action.error, isLoading: false };
    case types.CREATE_LITTLEMEMBERATTENDANCES_SUCCESS:
      return {
        ...state,
        attendanceResponse: action.response,
        isLoading: false,
        showModal: false,
        attendanceError: "",
      };
    default:
      return state;
  }
}
