/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { bindActionCreators } from "redux";
import * as eventActions from "../../redux/actions/eventActions";
import EventForm from './Event-form';
import { formattedDateTime } from "../../utils/Settings";

class EventNewContainer extends React.Component {
  componentDidMount() {
    this.state.event = this.state.event;
  }
  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      this.state = { key: "description" };
      const { actions, response } = this.props;
      if (response !== null) {
      }
      actions.fetchEvents();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      event: {
        title: "",
        details: "",
        startDateTime: formattedDateTime(new Date()),
        endDateTime: formattedDateTime(new Date()),
        active: true,
        uploads: [],
        sections: []
      },
      section: {
        body: "",
        category: "",
        heading: "",
        position: 1
      },
      showModal: false
    };
    this.myToast = null;
  }
   
  handleChange = (event) => {
    const { value, name } = event.target;
    let currentEvent = this.state.event;

    if (name === "uploads") {
      currentEvent.uploads.push(value);
    } else if (name === "active") {
      currentEvent = { ...this.state.event, [name]: event.target.checked };
    } else {
      currentEvent = { ...this.state.event, [name]: value };
    }
    this.setState({ event: currentEvent });
  };

  handleDeleteSection = (index) => {
    if (window.confirm("Do you want to delete this section?")) {
      let currentEvent = this.state.event;
      currentEvent.sections.splice(index,1);
      this.setState({ event: currentEvent });
    }
  };

  handleDelete = (index) => {
    if (window.confirm("Do you want to delete this file?")) {
      let currentEvent = this.state.event;
      currentEvent.uploads.splice(index,1);
      this.setState({ event: currentEvent });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { actions } = this.props;
    console.log(this.state.event);
    actions.createEvent(this.state.event);
    if (event !== null) {
      this.myToast = toast.success(
        `The event has been created successfully`
      );
    }
  };

  handleChangeSection = (event) => {
    const { value, name } = event.target;
    let currentSection = this.state.section;

    currentSection = { ...this.state.section, [name]: value };
    this.setState({ section: currentSection });
  };

  handleSubmitSection = (event) => {
    event.preventDefault();
    let currentEvent = this.state.event;
    currentEvent.sections.push(this.state.section);
    this.setState({ event: currentEvent });
    this.closeModal();
  };

  handleNewSection = (event) => {
    event.preventDefault();
    this.setState({showModal: true});
  };

  closeModal = () => {
    this.setState({showModal: false});
  };

  render() {
    const {
      isLoading = false
    } = this.props;
    const {event} = this.state;
    
    return (
      <Fragment>
        <div className="blue-strip">
          <div className="row">
            <div className="col-10">
              NEW EVENT
            </div>
          </div>
        </div>
        <EventForm
          event={event}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          isLoading={isLoading}
          handleDelete={this.handleDelete}
          handleDeleteSection={this.handleDeleteSection}
        />
      </Fragment>
    );
  }
}

EventNewContainer.propTypes = {
  actions: PropTypes.object.isRequired,
  event: PropTypes.object,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isLoadingEvents: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  events: PropTypes.array.isRequired,
  totalRecords: PropTypes.number.isRequired
};

function mapStateToProps(state) {
  return {
    events: state.events.events,
    totalRecords: state.events.totalRecords,
    users: state.events.users,
    error: state.events.error,
    isLoadingEvents: state.events.isLoadingEvents,
    isLoading: state.events.isLoading,
    showModal: state.events.showModal,
    event: state.events.event,
    response: state.events.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(eventActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventNewContainer);
