import axios from "axios";
import resolveBaseUrl from ".";
import { browserHistory } from 'react-router';

const baseUrl = resolveBaseUrl();

class EventAPI {
  static fetch(searchParams) {
    const {
      title= "",
      startDateTime= "",
      endDateTime= "",
      type= "",
      page = 1
    } = searchParams;

    const endDate = endDateTime.split(" ")[0];
    const startDate = startDateTime.split(" ")[0];
    return axios.get(`${baseUrl}/api/events?page=${page}&title=${title}&type=${type}&startDate=${startDate}&endDate=${endDate}`);
  }

  static fetchEvent(id) {
    return axios.get(`${baseUrl}/api/events/${id}`);
  }

  static createEvent(event) {
    return axios.post(`${baseUrl}/api/events`, event );
  }

  static editEvent(event) {
    console.log(event);
    browserHistory.push('/pathToRedirect')
    return axios.put(`${baseUrl}/api/events`, event);
  }

  static deleteEvent(id) {
    return axios.delete(`${baseUrl}/api/event/${id}`);
  }
}

export default EventAPI;
