/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import parse from 'html-react-parser';
import LoadingSpinner from "../common/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { handleSubmit, handleChange, user = {}, plan, forms = [], isLoading = false } = this.props;

    let loadingSpinner = "";
    if (isLoading) {
      loadingSpinner = (
        <span>
          <LoadingSpinner />
          <span> </span>
        </span>
      );
    }

    return (
      <Fragment>
        <div className="card text-white bg-dark mb-3">
          <div className="card-body">
            <h5 className="card-title">{plan.title}</h5>
            <p className="card-text">{parse(plan.info)}</p>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
              <div className="row">
                <div className="col-6">
                  <input
                    placeholder="Name"
                    className="form-control"
                    onChange={handleChange}
                    name="name"
                    value={user.name}
                  />
                </div>
                <div className="col-6">
                  <input
                    placeholder="Phone number"
                    className="form-control"
                    onChange={handleChange}
                    name="phone"
                    value={user.phone}
                  />
                </div>
              </div><br/>
              <div className="row">
                <div className="col-12">
                  <input
                    placeholder="Email"
                    className="form-control"
                    onChange={handleChange}
                    name="email"
                    value={user.email}
                  />
                </div>
              </div><br/>
              <div className="row">
                <div className="col-6">
                  <select
                      name="form"
                      className="form-control"
                      onChange={handleChange}
                      value={user.form}
                    >
                    <option value="">Class</option>
                      {forms && forms.map((s) => (
                        <option value={s} key={s}>
                          {s}
                        </option>
                        ))}
                  </select>
                </div>
                <div className="col-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => handleSubmit(event)}
                  >
                    {loadingSpinner} Register
                  </Button>
                </div>
              </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default RegisterForm;
