/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import RegisterForm from "./Register-form";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Users.scss";

toast.configure({
  autoClose: 8000,
  draggable: false,
});

class Users extends React.Component {
  componentDidMount() {
    const { actions, cActions } = this.props;
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response, cActions } = this.props;
      if (response !== null) {
        this.myToast = toast.success(
          `${response.name} has been saved successfully`
        );
      }
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      user: {
        form: "",
        email: "",
        name: "",
        phone: "",
        plan: this.props.location.state.plan,
        campus: 1
      },
      plan: this.props.location.state.plan
    };
    this.myToast = null;
    this.packages = {
      gold: {
        title: "Gold package",
        info:
          "&bull; Unlimited access to all content<br/>&bull; Access to all live classes<br/>&bull; Access to all practice questions and assessments",
      },
      premium: {
        title: "Premium package",
        info:
          "&bull; Content for all classes in a level<br/>&bull; 50% discount on all live classes<br/>&bull; Access to all practice questions and assessments in a level",
      },
      standard: {
        title: "Standard package",
        info:
          "&bull; Access to all content in 2 classes<br/>&bull; 10% discount on all live classes<br/>&bull; Access to all practice questions and assessments for 2 classes",
      },
    };
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    let currentUser = this.state.user;

    if (name === "attachments") {
      currentUser.attachments.append(value);
      console.log(event.target.value);
    } else if (name === "category") {
      currentUser = { ...this.state.user, [name]: { id: value } };
    } else if (name === "serviceProvider") {
      currentUser = { ...this.state.user, [name]: { id: value } };
    } else {
      currentUser = { ...this.state.user, [name]: value };
    }
    this.setState({ user: currentUser });
  };

  handleNewClick = (event, user) => {
    event.preventDefault();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { actions } = this.props;
    actions.signup(this.state.user);
  };

  render() {
    const {
      categories = [],
      isLoading = false,
      error = "",
      response = null
    } = this.props;

    if (error.length > 0) {
      this.myToast = toast.error(error);
    } else if (response !== null) {
      this.myToast = toast.success(
        `Your account has been created successfully`
      );
      this.props.history.push("/login");
    }

    const {user} = this.state;

    const forms = [
      "P1",
      "P2",
      "P3",
      "P4",
      "P5",
      "P6",
      "P7",
      "S1",
      "S2",
      "S3",
      "S4",
      "S5",
      "S6",
    ];

    const plan = this.packages[this.state.plan];
    return (
      <Fragment>
        {this.myToast !== null ? this.myToast : ""}
        <br />
        <br />
        <div className="container">
          <div className="row">
            <div className="col-6 col-lg-6 res-margin">
              <div className="section-title text-center text-lg-left">
                <h3>Create an account</h3>
                <p>
                  Get access to lots of content on different academic subjects
                  and topics. Our content is developed and produced by the
                  leading teachers and content producers in the country.
                </p>
              </div>

              <ul className="overview-list">
                <li>
                  <p>
                    <i className="fa-li fas fa-check"></i> Thousands of practice
                    questions (assessments) prepared and marked by some of the
                    best teachers.
                  </p>
                </li>

                <li>
                  <p>
                    <i className="fa-li fas fa-check"></i> Live webinars
                    conducted by leading instructors in the country.
                  </p>
                </li>

                <li>
                  <p>
                    <i className="fa-li fas fa-check"></i> High quality video
                    and animated content to make learning very interesting to
                    students of all levels.
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-6">
              <RegisterForm
                isLoading={isLoading}
                user={user}
                plan={plan}
                forms={forms}
                categories={categories}
                handleNewClick={this.handleNewClick}
                handleSubmit={this.handleSubmit}
                handleChange={this.handleChange}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Users.propTypes = {
  actions: PropTypes.object.isRequired,
  user: PropTypes.object,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    users: state.users.users,
    error: state.users.error,
    isLoading: state.users.isLoading,
    showModal: state.users.showModal,
    user: state.users.user,
    response: state.users.response,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
