/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

class SearchForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedRoles: [] };
  }

  render() {
    const classes = useStyles;

    const { handleSubmit, handleChange, users } = this.props;
    const roles = [
      { label: "ADMIN", value: "admin" },
      { label: "NORMAL USER", value: "user" },
      { label: "VOLUNTEER", value: "volunteer" },
    ];

    return (
      <Fragment>
        <div className="card">
          <div className="card-body">
            <form className={classes.root} noValidate autoComplete="off">
              <div className="row">
                <div className="col-3">
                  <input
                    placeholder="Name"
                    className="form-control"
                    onChange={handleChange}
                    name="name"
                  />
                </div>
                <div className="col-3">
                  <input
                    placeholder="Phone"
                    className="form-control"
                    onChange={handleChange}
                    name="phone"
                  />
                </div>
                <div className="col-3">
                  <select
                    name="role"
                    className="form-control"
                    placeholder="Status"
                    onChange={handleChange}
                  >
                    <option value="">Category</option>
                    {roles.map((s) => (
                      <option value={s.value} key={s.value}>
                        {s.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-2">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => handleSubmit(event)}
                  >
                    Search
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default SearchForm;
