import { put, takeLatest, call } from "redux-saga/effects";
import { GET_SERVICEAREAS, CREATE_SERVICEAREA, GET_SERVICEAREAS_DETAILS, CREATE_VOLUNTEER, CREATE_CHECKLISTTEMPLATE, CREATE_CHECKLISTTEMPLATE_ITEM } from "../actions/actionTypes";
import ServiceAreaAPI from "../../services/ServiceAreaAPI";
import {
  fetchServiceAreasSuccess,
  fetchServiceAreasFailure,
  createServiceAreaSuccess,
  createServiceAreaFailure,
  fetchServiceAreaDetailsFailure,
  fetchServiceAreaDetailsSuccess,
  createVolunteerSuccess,
  createVolunteerFailure,
  createChecklistTemplateSuccess,
  createChecklistTemplateFailure,
  createChecklistTemplateItemSuccess,
  createChecklistTemplateItemFailure
} from "../actions/serviceAreaActions";

import apiErrorHandler from "../../services/apiErrorHandler";

export function* fetchServiceAreasAsync(action) {
  try {
    const response = yield call(ServiceAreaAPI.fetch);
    yield put(fetchServiceAreasSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(fetchServiceAreasFailure(errorMessage));
  }
}

export function* fetchServiceAreaDetailsAsync(action) {
  try {
    const response = yield call(ServiceAreaAPI.details, action.serviceAreaId);
    yield put(fetchServiceAreaDetailsSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(fetchServiceAreaDetailsFailure(errorMessage));
  }
}

export function* createServiceAreaAsync(action) {
  try {
    const response = yield call(ServiceAreaAPI.create, action.record);
    yield put(createServiceAreaSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(createServiceAreaFailure(errorMessage));
  }
}

export function* createVolunteerAsync(action) {
  try {
    const response = yield call(ServiceAreaAPI.volunteer, action.record, action.id);
    yield put(createVolunteerSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(createVolunteerFailure(errorMessage));
  }
}

export function* createChecklistTemplateAsync(action) {
  try {
    const response = yield call(ServiceAreaAPI.checklistTemplate, action.record);
    yield put(createChecklistTemplateSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(createChecklistTemplateFailure(errorMessage));
  }
}

export function* createChecklistTemplateItemAsync(action) {
  try {
    const response = yield call(ServiceAreaAPI.checklistTemplateItem, action.record);
    yield put(createChecklistTemplateItemSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(createChecklistTemplateItemFailure(errorMessage));
  }
}

export function* watchServiceAreas() {
  yield takeLatest(GET_SERVICEAREAS, fetchServiceAreasAsync);
}

export function* watchServiceAreaDetails() {
  yield takeLatest(GET_SERVICEAREAS_DETAILS, fetchServiceAreaDetailsAsync);
}

export function* watchCreateServiceArea() {
  yield takeLatest(CREATE_SERVICEAREA, createServiceAreaAsync);
}

export function* watchCreateVolunteer() {
  yield takeLatest(CREATE_VOLUNTEER, createVolunteerAsync);
}

export function* watchCreateChecklistTemplate() {
  yield takeLatest(CREATE_CHECKLISTTEMPLATE, createChecklistTemplateAsync);
}

export function* watchCreateChecklistTemplateItem() {
  yield takeLatest(CREATE_CHECKLISTTEMPLATE_ITEM, createChecklistTemplateItemAsync);
}
