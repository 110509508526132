/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Route, Link, Routes, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as serviceAreaActions from "../../redux/actions/serviceAreaActions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Preloader from "../Preloader/Preloader";
import { isAdmin } from "../../utils/Authentication";
import Modal from "../common/modal/Modal";
import VolunteerForm from "./VolunteerForm";
import CheclistTemplateForm from "./ChecklistTemplateForm";
import CheclistTemplateItemForm from "./ChecklistTemplateItemForm";

toast.configure({
  autoClose: 8000,
  draggable: false,
});

class Details extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.fetchServiceArea(this.props.match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.response !== prevProps.response) {
      const { actions, response } = this.props;
      if (response != null) {
        actions.fetchServiceArea(this.props.match.params.id);
        this.closeChecklistModal();
      }
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      key: "description",
      showModal: false,
      showChecklistModal: false,
      showItemModal: false,
      volunteer: { name: "", email: "", phone: "", category: "Member" },
      checklisttemplate: { title: "", serviceArea: {} },
      checklisttemplateItem: { title: "", checklistTemplate: {} },
    };
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    let currentVolunteer = this.state.volunteer;

    currentVolunteer = { ...this.state.volunteer, [name]: value };
    this.setState({ volunteer: currentVolunteer });
  };

  handleChangeChecklistTemplate = (event) => {
    const { value, name } = event.target;
    let currentRecord = this.state.checklisttemplate;

    currentRecord = { ...this.state.checklisttemplate, [name]: value };
    this.setState({ checklisttemplate: currentRecord });
  };

  handleChangeChecklistTemplateItem = (event) => {
    const { value, name } = event.target;
    let currentRecord = this.state.checklisttemplateItem;

    currentRecord = { ...this.state.checklisttemplateItem, [name]: value };
    this.setState({ checklisttemplateItem: currentRecord });
  };

  handleNewClick = (event, serviceArea) => {
    event.preventDefault();
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const { actions } = this.props;
    actions.createVolunteer(this.state.volunteer, this.props.match.params.id);
  };

  handleSubmitChecklistTemplate = (event) => {
    event.preventDefault();
    const { actions } = this.props;
    const record = this.state.checklisttemplate;
    record.serviceArea = { id: this.props.serviceArea.id };
    actions.createChecklistTemplate(record);
  };

  handleSubmitItemTemplate = (event) => {
    event.preventDefault();
    const { actions } = this.props;
    const record = this.state.checklisttemplateItem;
    actions.createChecklistTemplateItem(record);
  };

  handleNewVolunteer = (event) => {
    event.preventDefault();
    this.setState({ showModal: true });
  };

  handleNewChecklistTemplate = (event) => {
    event.preventDefault();
    this.setState({ showChecklistModal: true });
  };

  handleNewitem = (item) => {
    let currentRecord = this.state.checklisttemplateItem;

    currentRecord = { ...this.state.checklisttemplateItem, checklistTemplate: item };
    this.setState({ checklisttemplateItem: currentRecord });
    this.setState({ showItemModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  closeChecklistModal = () => {
    this.setState({ showChecklistModal: false });
  };

  closeItemModal = () => {
    this.setState({ showItemModal: false });
  };

  render() {
    let { serviceArea = null, isLoading = true } = this.props;
    const {
      showModal = false,
      volunteer,
      checklisttemplate,
      checklisttemplateItem,
      showChecklistModal = false,
      showItemModal = false
    } = this.state;

    const visibility = showModal ? "visible" : "invisible";
    return !isLoading ? (
      <Fragment>
        {showModal ? (
          <Modal
            visibility={visibility}
            modalContentId="newOrderItemId"
            modalId="newOrderId"
            closeModal={this.closeModal}
            title="New Volunteer"
          >
            <VolunteerForm
              isLoading={isLoading}
              record={volunteer}
              handleSubmit={this.handleSubmit}
              handleChange={this.handleChange}
            />
          </Modal>
        ) : null}
        {showChecklistModal ? (
          <Modal
            visibility={showChecklistModal ? "visible" : "invisible"}
            modalContentId="newOrderItemId"
            modalId="newOrderId"
            closeModal={this.closeChecklistModal}
            title="New Checklist"
          >
            <CheclistTemplateForm
              isLoading={isLoading}
              record={checklisttemplate}
              handleSubmit={this.handleSubmitChecklistTemplate}
              handleChange={this.handleChangeChecklistTemplate}
            />
          </Modal>
        ) : null}
        {showItemModal ? (
          <Modal
            visibility={showItemModal ? "visible" : "invisible"}
            modalContentId="newOrderItemId"
            modalId="newOrderId"
            closeModal={this.closeItemModal}
            title="New Checklist item"
          >
            <CheclistTemplateItemForm
              isLoading={isLoading}
              record={checklisttemplateItem}
              handleSubmit={this.handleSubmitItemTemplate}
              handleChange={this.handleChangeChecklistTemplateItem}
            />
          </Modal>
        ) : null}
        <div className="blue-strip">
          <div className="row">
            <div className="col-8">
              {serviceArea.name}
              <div>
                <i>{serviceArea.details}</i>
              </div>
            </div>
            {isAdmin() ? (
              <div className="col-4">
                <div className="edit-content">
                  <Link
                    to={{
                      pathname: `/serviceareas/${serviceArea.id}/edit/`,
                      state: {
                        serviceArea: serviceArea,
                      },
                    }}
                    className="custom-btn d-inline-flex align-items-center m-2 m-sm-0"
                  >
                    <i className="fa fa-edit"></i> Edit
                  </Link>
                  &nbsp;&nbsp;
                  <button
                    className="custom-btn d-inline-flex align-items-center m-2 m-sm-0"
                    onClick={this.handleNewVolunteer}
                  >
                    New Volunteer
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="custom-btn d-inline-flex align-items-center m-2 m-sm-0"
                    onClick={this.handleNewChecklistTemplate}
                  >
                    New Template
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <br />
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="card">
                <div className="card-header">Members</div>
                <ul class="list-group list-group-flush">
                  {serviceArea.members.map((member) => {
                    return (
                      <li class="list-group-item" key={member.id}>
                        <div>
                          <strong>{member.user.name}</strong> [{member.category}
                          ]
                        </div>
                        <div>
                          {member.user.phone} {member.user.email}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-6">
              <div className="card">
                <div className="card-header">Checklists</div>
                <ul class="list-group list-group-flush">
                  {serviceArea.checklistTemplates.map((member) => {
                    return (
                      <>
                      <li class="list-group-item" key={member.id}>
                        <div>
                          <strong>{member.title}</strong> &nbsp;&nbsp;&nbsp; 
                          <a
                            className="btn-action-primary"
                            href="#"
                            onClick={() => {
                              this.handleNewitem(member)
                            }}
                          >
                            &nbsp;+Add Item&nbsp;
                          </a>
                        </div>
                      </li>
                      {member.items.map((item) => {
                        return (
                        <li class="list-group-item" key={`si-${item.id}`}><i className="fa fa-check-square"></i> {item.details}</li>
                        )
                      })}
                      </>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    ) : (
      <Preloader />
    );
  }
}

Details.propTypes = {
  actions: PropTypes.object.isRequired,
  serviceArea: PropTypes.object,
  volunteer: PropTypes.object,
  response: PropTypes.object,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  showModal: PropTypes.bool.isRequired,
  serviceAreas: PropTypes.array.isRequired,
};

function mapStateToProps(state) {
  return {
    serviceAreas: state.serviceAreas.serviceAreas,
    error: state.serviceAreas.error,
    isLoading: state.serviceAreas.isLoading,
    showModal: state.serviceAreas.showModal,
    serviceArea: state.serviceAreas.serviceArea,
    volunteer: state.serviceAreas.volunteer,
    response: state.serviceAreas.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(serviceAreaActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(Details);
