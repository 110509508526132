import React from "react";
import ReactHlsPlayer from "react-hls-player";
import { Settings } from "../../../utils/Settings";

export default function Media(props) {
  const { file } = props;

  return (
    <ReactHlsPlayer
      src={Settings.getFile(file.transcodedUrl)}
      autoPlay={false}
      controls={true}
      width="100%"
      height="auto"
    />
  );
}
