/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LoadingSpinner from "../common/LoadingSpinner";
import { makeStyles } from "@material-ui/core/styles";
import Upload from "../common/Upload";
import UploadChunked from "../common/UploadChunked";
import { Settings } from "../../utils/Settings";
import ContentSectionList from "./Content-section-list-view";
import { Editor } from "@tinymce/tinymce-react";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

const mapStyles = {
  width: "100%",
  height: "100%",
};

class ContentForm extends React.Component {
  constructor(props) {
    super(props);
  }

  itemCard = (file, handleDelete, index) => {
    return (
      <li className="list-group-item" key={file.id}>
        <button
          onClick={() => {
            handleDelete(index);
          }}
          className="file-item"
        >
          <i className={`fa ${this.getIconName(file.path)}`}></i>{" "}
          {file.name != null ? file.name : file.path}
        </button>
      </li>
    );
  };

  getIconName = (fileName) => {
    let icon = "fa-file-pdf-o";
    if (fileName.endsWith(".mp4")) {
      icon = "fa-play";
    } else if (fileName.endsWith(".docx") || fileName.endsWith(".doc")) {
      icon = "fa-file-word-o";
    }
    return icon;
  };

  render() {
    const {
      handleSubmit,
      handleChange,
      content,
      isLoading = false,
      handleDelete,
      handleDeleteSection,
    } = this.props;
    let loadingSpinner = "";
    if (isLoading) {
      loadingSpinner = (
        <span>
          <LoadingSpinner />
          <span> </span>
        </span>
      );
    }
    return (
      <Fragment>
        <br />
        <div className="main-container">
          <div className="  row">
            <div className="col-9">
              <div className="row">
                <div className="col-2">
                  <select
                    name="category"
                    className="form-control"
                    onChange={handleChange}
                    value={content.category}
                  >
                    <option value="">Category</option>
                      {["Page","Blog","Resource", "Slider", "Devotional", "Update"].map((s) => (
                        <option value={s} key={`t${s}`}>
                          {s}
                        </option>
                        ))}
                  </select>
                </div>
                <div className="col-4">
                  <input
                    placeholder="Title"
                    onChange={handleChange}
                    name="title"
                    value={content.title}
                    className="form-control"
                  />
                </div>
                <div className="col-2">
                  <input
                    placeholder="Position"
                    onChange={handleChange}
                    name="position"
                    type="number"
                    value={content.position}
                    className="form-control"
                  />
                </div>
                <div className="col-2">
                  <input
                    onChange={handleChange}
                    name="active"
                    type="checkbox"
                    value={true}
                    checked={content.active ? "checked" : ""}
                    className="inline"
                  />{" "}
                  Is Active
                </div>
                <div className="col-2">
                  <input
                    onChange={handleChange}
                    name="showDetails"
                    type="checkbox"
                    value={true}
                    checked={content.showDetails ? "checked" : ""}
                    className="inline"
                  />{" "}
                  Show Details
                </div>
              </div>
              <br />
              <div className="tab-contents">
                <div className="row">
                  <div className="col-10">
                    <Upload
                      handleSubmit={(response) => {
                        handleChange({
                          target: { name: "cover", value: response.data },
                        });
                      }}
                      multiple={false}
                      title="Cover photo"
                    />
                  </div>
                  <div className="col-2">
                    {content.cover && content.cover.id > 0 ? (
                      <img src={Settings.getFile(content.cover.path)} />
                    ) : null}
                  </div>
                </div>
              </div>
              <br />
              <div className="tab-contents">
                <div>
                  <Editor
                    tinymceScriptSrc={
                      process.env.PUBLIC_URL +
                      "/tinymce/js/tinymce/tinymce.min.js"
                    }
                    
                    init={{
                      height: 300,
                      menubar: false,
                      contextmenu: false,
                      branding: false, // Disable "Powered by Tiny" brandin
                    }}
                    value={content.details ? content.details : ""}
                    plugins = "link image table"
                    toolbar = 'undo redo styles bold italic alignleft aligncenter alignright alignjustify fontsize blockquote | table | bullist numlist outdent indent link image'
                    quickbars_insert_toolbar = {true}
                    onEditorChange={(c) => {
                      const event = { target: { name: "details", value: c } };
                      handleChange(event);
                    }}
                  />
                </div>
              </div>
              <br />
              <ContentSectionList
                contents={content.sections}
                handleDelete={handleDeleteSection}
              />
              <br />
              <button className="btn" onClick={handleSubmit}>
                {loadingSpinner}
                Save
              </button>
            </div>
            <div className="col-3">
              {content.uploads && content.uploads.length > 0 ? (
                <ul className="list-group">
                  {content.uploads.map((file, i) => {
                    return this.itemCard(file, handleDelete, i);
                  })}
                </ul>
              ) : null}
              <div className="tab-contents">
                <Upload
                  handleSubmit={(response) => {
                    handleChange({
                      target: { name: "uploads", value: response.data },
                    });
                  }}
                  multiple={true}
                  title="Resources (pdf, word, audio, images...)"
                  handleDelete={handleDelete}
                />
              </div>
              <div className="tab-contents">
                <UploadChunked
                  handleSubmit={(response) => {
                    handleChange({
                      target: { name: "uploads", value: response.data.upload },
                    });
                  }}
                  title="Videos"
                  handleDelete={handleDelete}
                />
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}
export default ContentForm;
