/* eslint-disable react/forbid-prop-types */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as userActions from "../../redux/actions/userActions";
import LoginForm from "./Login-view";
import LoginOtp from "./Login-otp";
import { Redirect } from "react-router-dom";
import {
  processLoginResponse,
  isAuth,
  processOtpLoginResponse,
} from "../../utils/Authentication";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {
        phone: "",
        password: "",
        email: "",
      },
      error: "",
    };
  }

  handleChange = (event) => {
    const { value, name } = event.target;
    const user = { ...this.state.user, [name]: value };
    console.log(user);
    this.setState({ user });
  };

  handleSubmit = (event, type) => {
    event.preventDefault();
    const { user } = this.state;
    const error = this.validateInputs(user);
    const { actions } = this.props;
    if (error.length > 0 && type === "otp") {
      actions.validationError(error);
    } else {
      if (type === "otp") {
        user.phone = this.sanitizePhoneNumber(user.phone);
        user.email = user.phone;
        actions.fetchOtp(user);
      } else {
        actions.loginOtp(user);
      }
    }
  };

  sanitizePhoneNumber = (phone) => {
    return "256" + phone.slice(-9);
  };

  validateInputs = (user) => {
    let error = "";
    if (this.sanitizePhoneNumber(user.phone).length !== 12) {
      error = "Invalid phone number";
    }
    return error;
  };

  render() {
    const {
      error,
      isLoading = false,
      response = null,
      showOtpScreen = false,
    } = this.props;
    const { user } = this.state;
    if (response != null && showOtpScreen) {
      processOtpLoginResponse(response);
    }
    return isAuth() ? (
      <Redirect to="/" />
    ) : (
      <div className="container">
        <br/>
        <div className="row">
          <div className="col-3">
            <div className="section-title text-center text-lg-left">
              
            </div>
          </div>
          <div className="col-6">
            <h3>Login here</h3>
            {
              showOtpScreen ? (
                <LoginOtp
                  error={error}
                  isLoading={isLoading}
                  handleChange={this.handleChange}
                  handleSubmit={this.handleSubmit}
                  validateInputs={this.validateInputs}
                  user={user}
                />
              ) : (
                <LoginForm
                  error={error}
                  isLoading={isLoading}
                  handleChange={this.handleChange}
                  handleSubmit={this.handleSubmit}
                  validateInputs={this.validateInputs}
                  user={user}
                />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  actions: PropTypes.object.isRequired,
  error: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  showOtpScreen: PropTypes.bool.isRequired,
  response: PropTypes.object,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    password: PropTypes.string.isRequired,
  }).isRequired,
};

function mapStateToProps(state) {
  return {
    user: state.users.user,
    error: state.users.error,
    isLoading: state.users.isLoading,
    showOtpScreen: state.users.showOtpScreen,
    response: state.users.response,
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
