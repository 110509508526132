import React from "react";
import { Link } from "react-router-dom";
import parse from 'html-react-parser';
import Cover from "../../../assets/images/cover.png";
import { Settings, formatDate } from "../../../utils/Settings";

function Featured(props) {
  const { contents = [], category = "Update" } = props;

  return (
    <>
      <section id="pages-general">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              <div className="section-title text-center">
                {category == "Devotional" ? (<div>
                  <h3>Daily devotional messages</h3>
                  <p>
                    <q>
                      <blockquote>Let the word of Christ dwell in you richly in all wisdom; teaching and admonishing one another in psalms and hymns and spiritual songs, singing with grace in your hearts to the Lord. - Col 3:16</blockquote>
                    </q>
                  </p></div>) : (<></>)}
                  {category == "Update" ? (<div>
                  <h3>Encounter Church - Updates</h3>
                  <p>
                    <q>
                      Stay upto date with what is happening at Encounter Church. Find out when different events and activities will be happening
                    </q>
                  </p></div>) : (<></>)}
              </div>
            </div>
          </div>
          <div className="row blog-home">
            {contents.map((category) => {
              return CategoryCard(category);
            })}
          </div>
        </div>
      </section>
    </>
  );
}

function CategoryCard(category) {
  return (
    <div className="col-12 col-lg-4 res-margin" key={category.id}>
      <div className="blog-col">
        <p>
          <img src={(category.cover != null) ? Settings.getFile(category.cover.path) : Cover} className="blog-img" alt="" />
        </p>

        <div className="blog-wrapper">
          <div className="blog-text">
            <p className="blog-about">
              <span>{category.category}</span>
            </p>

            <h4>
              <a href={`/content/${category.id}`}>
                {category.title}
              </a>
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Featured;
