/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import LoadingSpinner from "../common/LoadingSpinner";
import {
  convertShortDate,
  formattedDate,
  convertDate,
  formattedDateTime,
} from "../../utils/Settings";
import DatePicker from "react-datepicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

class AttendanceForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedRoles: [] };
  }

  render() {
    const classes = useStyles;

    const {
      handleSubmit,
      handleChange,
      littleMemberAttendance,
      handleClickRegisterParent,
      title = "New User",
      isLoading = false,
      users = [],
    } = this.props;

    let loadingSpinner = "";
    if (isLoading) {
      loadingSpinner = (
        <span>
          <LoadingSpinner />
          <span> </span>
        </span>
      );
    }

    return (
      <Fragment>
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-body">
            <form className={classes.root} noValidate autoComplete="off">
              <div><b>{littleMemberAttendance.member ? littleMemberAttendance.member.firstname + ' ' +  littleMemberAttendance.member.lastname: "N/A"}</b></div><br/>
              <div className="row">
                <div className="col-12">
                  <DatePicker
                    name="arrivalDateTime"
                    valueName="selected"
                    placeholderText="Arrival Date &amp; Time"
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    showTimeSelect
                    timeFormat="HH:mm"
                    portalId="root-portal"
                    value={
                      littleMemberAttendance.arrivalDateTime
                        ? littleMemberAttendance.arrivalDateTime
                        : ""
                    }
                    selected={
                      littleMemberAttendance.arrivalDateTime
                        ? convertDate(littleMemberAttendance.arrivalDateTime)
                        : new Date()
                    }
                    onChange={(date) => {
                      const event = {
                        target: {
                          name: "arrivalDateTime",
                          value: formattedDateTime(date),
                        },
                      };
                      handleChange(event);
                    }} //only when value has changed
                    minDate={new Date()}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-8">
                  <select
                    name="user"
                    className="form-control"
                    onChange={handleChange}
                    value={
                      littleMemberAttendance.user &&
                      littleMemberAttendance.user.id
                        ? littleMemberAttendance.user.id
                        : ""
                    }
                  >
                    <option value="">Parent / Guardian</option>
                    {users.map((user) => {
                      return (
                        <option value={user.id} key={user.id}>
                          {user.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-4">
                  <a
                    className="a-action-primary"
                    href="#"
                    onClick={() => {
                      handleClickRegisterParent();
                    }}
                  >
                    [+Register]
                  </a>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <input
                    placeholder="Comment"
                    className="form-control"
                    onChange={handleChange}
                    value={littleMemberAttendance.remark}
                    name="remark"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => handleSubmit(event, this.country)}
                  >
                    {loadingSpinner} Save
                  </Button>
                </div>
              </div>
            </form>
            <p>&nbsp;</p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default AttendanceForm;
