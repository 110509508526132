import React from "react";
import Cover1 from "../../assets/images/covers/sunday.png";
import Cover2 from "../../assets/images/covers/children.png";
import Cover3 from "../../assets/images/covers/encounter.png";

function Ministry(props) {
	return (
		<div className="categories-wrapper">
			<section id="church-life">
				<div className="container">

					<div className="row">
						<div className="col-12 col-md-12 col-lg-4">
							<div className="ministry-card">
								<div className="box-text media-body align-self-center align-self-md-start">
									<h4>Nothing is impossible</h4>
									<p>In whatever we do, we carry a mindset that nothing is impossible. Nothing is impossible to them that believe</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-4">

							<div className="ministry-card">
								<div className="box-text media-body align-self-center align-self-md-start">
									<h4>We speak dominion</h4>
									<p>We are agents of change in this world designed by God to carry out his mission of new life in every aspect of our culture (business, arts/entertainment, education,
										media, government).</p>
								</div>

							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-4">

							<div className="ministry-card">
								<div className="box-text media-body align-self-center align-self-md-start">
									<h4>We preach the gospel</h4>
									<p>In our songs, in our services, with our words and with our lives we celebrate and share the death, burial and resurrection of Jesus Christ in a way people can understand and relate to.</p>
								</div>
							</div>
						</div>
					</div>
					<br />
					<div className="row d-flex align-items-center">
						<div className="col-12 col-md-12 col-lg-4">
							<ul className="features-item">
								<li>
									<div className="feature-box media">
										<div className="box-text media-body align-self-center align-self-md-start">
											<h4>Holy Bible</h4>
											<p>The Holy Bible, and only the Bible, is the authoratative Word of God. It alone is the
												final authority in determining all doctrinal truths. In its original writing, it is inspired,
												infallable, and inerrant. (II Timothy 3:16; II Peter 1:20–21; Proverbs 30:5; Romans
												16:25–26).</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
						<div className="col-12 col-md-12 col-lg-4">

							<ul className="features-item">
								<li>
									<div className="feature-box media">
										<div className="box-text media-body align-self-center align-self-md-start">
											<h4>Trinity</h4>
											<p>There is one God,eternally existant in three persons: Father, Son, and Holy Spirit.
												These three are co-equal and co-eternal (I John 5:7; Genesis 1:26; Matthew
												3:16–17, 28:19; Luke 1:35; Isaiah 9:6; Hebrews 3:7–11).</p>
										</div>

									</div>
								</li>
							</ul>
						</div>
						<div className="col-12 col-md-12 col-lg-4">

							<ul className="features-item">
								<li>
									<div className="feature-box media">
										<div className="box-text media-body align-self-center align-self-md-start">
											<h4>Salvation</h4>
											<p>We are saved by grace through faith in Jesus Christ; His death, burial, and
												resurrection. Salvation is a gift from God, not a result of our good works or of any
												human efforts (Ephesians 2:8–9; Galatians 2:16, 3:8; Titus 3:5; Romans 10:9–10;
												Acts 16:31; Hebrews 9:22).</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>

			</section>
		</div>
	);
}

export default Ministry;
