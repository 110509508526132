import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Image1 from "../../../assets/images/slider/image1.jpg";
import Image2 from "../../../assets/images/slider/image2.jpg";

function General(props) {
	return (
		<section id="about">
			<div className="container">
			<div className="about-header">About Encounter Church</div>
				<div className="general-info">
					The Lord placed a burden upon Pastors Andrew and Angela Kimuli to help people encounter His presence through the power of Holy Spirit.
					On 4th Septemeber 2022, they planted Encounter Church in obedience to the voice and call of the Lord.
				</div>
				<div className="about-sub-header">Vision and Pillars</div>
				<div className="vision">
				Our vision at Encounter Church is for each one of us to Encounter Jesus through the
power of the Holy Spirit and have Dominion!
				</div>
				<br/>
				<div>
					<div className="row">
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Decision to follow Jesus</div>
									<p>It all starts here! When we make a Decision to follow Jesus! We enter His kingdom
and start our journey to Dominion.
John 3:3 reminds us that in order for us to see the kingdom of God we have to make
a Decision to be born again. This is the ONLY gate to the kingdom of God and it’s
through Jesus Christ alone.</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Deliverance</div>
									<p>When we make a decision to follow Jesus, it doesn&#39;t always lead to deliverance from
old habits.
We believe deliverance is possible when we totally surrender to Jesus and his word
and allow Holy Spirit to deal with our old habits.
Both Jesus in the gospels and Paul in acts 19 performed the ministry of deliverance
to the multitudes.</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Discover purpose</div>
									<p>We are all created with Purpose and our role is to discover that Purpose and live it
out for the glory of The Father.
At Encounter, we are committed to discipling you to discover your purpose and live it
out. Jesus our model was born to save the world and after calling Peter he changed his
purpose from fishing to being a fisher of men.</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Dominion</div>
									<p>When God created Adam and Eve he created them for dominion (Gen 1:28). God
wanted Adam and Eve to rule and have dominion over God’s creation.
This mandate is still alive! Jesus came to redeem us so that we can have dominion.
Dominion is a place where each of us as Christians was created to live.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br/>
				<div className="about-sub-header">The seven cornerstones</div>
				<br/>
				<div>
					<div className="row">
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">NOTHING IS IMPOSSIBLE</div>
									<p>In whatever we do, we carry a mindset that nothing is impossible. Nothing is
impossible to them that believe</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">WE SPEAK DOMINION</div>
									<p>We are agents of change in this world designed by God to carry out his mission of
new life in every aspect of our culture (business, arts/entertainment, education,
media, government).</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">WE PREACH THE GOSPEL</div>
									<p>In our songs, in our services, with our words and with our lives we celebrate and
share the death, burial and resurrection of Jesus Christ in a way people can
understand and relate to.</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">WE LOVE PEOPLE</div>
									<p>All of them! We don’t discriminate from backgrounds, shapes, colors, sizes,
educations, salaries, single, married, young, old, churched, dechurched, unchurched
etc.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br/>
				<div>
					<div className="row">
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">WE PROTECT UNITY</div>
									<p>The poisons of gossip, complaining, backbiting, discord and division are not welcome
here. Nor will they be tolerated.</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">WE LEAD LIKE CHAMPIONS</div>
									<p>Our confidence and competence is not based on our skill and talent, but on the
glorious victory of our Savior Jesus Christ, who through His death, burial and
resurrection, defeated darkness and redeemed the world. His victory is now our
victory.</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">WE LIVE A SURREDERED LIFE.</div>
									<p>This is a life of humility and complete trust in the Lord and His Word. Our lives are
hidden in Christ and so we are not mastered by sin but empowered to overcome it.</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									
								</div>
							</div>
						</div>
					</div>
				</div>
				<br/>
				<div className="about-sub-header">What we believe</div>
				<br/>
				<div>
					<div className="row">
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Holy Bible</div>
									<p>The Holy Bible, and only the Bible, is the authoratative Word of God. It alone is the
final authority in determining all doctrinal truths. In its original writing, it is inspired,
infallable, and inerrant. (II Timothy 3:16; II Peter 1:20–21; Proverbs 30:5; Romans
16:25–26).</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Trinity</div>
									<p>There is one God, eternally existant in three persons: Father, Son, and Holy Spirit.
These three are co-equal and co-eternal (I John 5:7; Genesis 1:26; Matthew
3:16–17, 28:19; Luke 1:35; Isaiah 9:6; Hebrews 3:7–11).</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Salvation</div>
									<p>We are saved by grace through faith in Jesus Christ; His death, burial, and
resurrection. Salvation is a gift from God, not a result of our good works or of any
human efforts (Ephesians 2:8–9; Galatians 2:16, 3:8; Titus 3:5; Romans 10:9–10;
Acts 16:31; Hebrews 9:22).</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Redemption</div>
									<p>Man was created good and upright. But by voluntary transgression he fell; his only
hope of redemption was in Jesus Christ, the Son of God (Genesis 1:26–31, 3:1–7;
Romans 5:12–21).</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br/>
				<div>
					<div className="row">
						<div className="col-12 col-md-12 col-lg-6">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">The church</div>
									<p>The Church is the body of Christ, the habitation of God through the Spirit, with
divine appointments for the fulfillment of Jesus&#39; great comission. Every person who
is born of the Spirit is an integral part of the Church as a member of the body of
believers. There is a spiritual unity of all believers in our Lord Jesus Christ.
(Ephesians 1:22, 2:19–22; Hebrews 12:23; John 17:11, 20–23).</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-6">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Jesus Christ</div>
									<p>Jesus Christ is God the Son, the second person of the trinity. On Earth, Jesus was
100% God and 100% man. He is the only man ever to have lived a sinless life. He
was born of a virgin, lived a sinless life, performed miracles, died on the cross for
mankind and thus, atoned for our sins through the shedding of His blood. He rose
from the dead on the third day according to the scriptures, ascended to the right
hand of the father and will return again in power and glory. (John 1:1,14, 20:28; I
Timothy 3:16; Isaiah 9:6; Philippians 2:5–6; I Timothy 2:5).</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br/>
				<div>
					<div className="row">
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Repentance</div>
									<p>Repentance is the commitment to turn away from sin in every area of our lives and
to follow Christ, which allows us to receive His redemption and to be regenerated by
the Holy Spirit. Thus, through repentance we receive forgiveness of sins and
appropriate salvation. (Acts 2:21, 3:19; I John 1:9).</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Sanctification</div>
									<p>Sanctification is the ongoing process of yielding to God&#39;s word and His spirit in order
to complete the development of Christ&#39;s character in us.It is through the present
ministry of the Holy Spirit and the word of God the Christian is enabled to live a
Godly life. (I Thessalonians 4:3, 5:23; II Corinthians 3:18, 6:14–18, II Thessalonians
2:1–3, Romans 8:29, 12:1–2, Hebrews 2:11).</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Virgin Birth</div>
									<p>Jesus Christ was conceived by God the Father, through the Holy Spirit (the third
person of the trinity) in the Virgin Mary&#39;s womb; therefore, He is the son of God.
(Isaiah 7:14; Matthew 1:18, 23–25; Luke 1:27–35).</p>
								</div>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-3">
							<div className="">
								<div className="box-text media-body align-self-center align-self-md-start">
									<div className="about-sub-sub-header">Jesus&#39; Blood</div>
									<p>The blood Jesus Christ shed on the cross of calvary was sinless and is 100%
sufficient to cleanse mankind of all sin. Jesus allowed himself to be punished for
both our sinfulness and our sins, enabling all those who believe to be free from the
penalty of sin, which is death. (I John 1:7; Revelation 1:5, 5:9; Colossians 1:20;
Romans 3:10–12, 23, 5:9; John 1:29).</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				<br/>
			</div>
		</section>
	);
}

export default General;
