import React from "react";
import { Link } from "react-router-dom";
import Cover from "../../../assets/images/team/cover.png";
import AndrewPhoto from "../../../assets/images/team/andrew.png";
import DavidPhoto from "../../../assets/images/team/david.png";
import RogerPhoto from "../../../assets/images/team/roger.png";
import JohnPhoto from "../../../assets/images/team/john.png";
import SimonPhoto from "../../../assets/images/team/simon.png";
import TurigyePhoto from "../../../assets/images/team/turigye.png";
import { Settings, formatDate } from "../../../utils/Settings";

function Team(props) {
  const members = [
    {
      name: "Andrew & Angela Kimuli",
      title: "Founding pastors",
      bio: "Pastors Andrew and Angela have the privelege and responsibility of leading Encounter Church. Each day they are devoted to answering the call of the Lord upon their lives.",
      photo: AndrewPhoto,
      id: 1
    },{
      name: "Simon & Anitah Senteza",
      title: "Associate pastors - Prayer",
      bio: "Simon and Anita have been called into prayer. With such a passion for healing and deliverance, they oversee the prayer ministry as well as University related activities.",
      photo: SimonPhoto,
      id: 2
    },{
      name: "John & Diana Bukenya",
      title: "Associate pastors - Discipleship",
      bio: "Pastor John has a deep desire for people to know the Lord. His wife Diana is a teacher of the word. The Bukenyas are fully responsible for the discipleship ministry.",
      photo: JohnPhoto,
      id: 3
    },{
      name: "Rogers & Carol Obbo",
      title: "Associate pastors - Worship",
      bio: "Rogers and Carol work tirelessly to ensure that we have an amazing worship experience whenever we met. They oversee our team of singers and instrumentalists",
      photo: RogerPhoto,
      id: 4
    },{
      name: "David & Phiona Ssali",
      title: "Associate pastors - Ministry teams",
      bio: "For a church to remain healthy, the people that serve need to be pastored. David and Phiona are excited to offer postoral support to all our ministry teams.",
      photo: DavidPhoto,
      id: 5
    },{
      name: "Charles & Phiona Kabiswa",
      title: "Deacons - Administration",
      bio: "There is a lot of administration related duties at Encounter Church. To ensure the smooth running of the ministry, Charles & Phiona have embraced this role.",
      photo: Cover,
      id: 6
    },{
      name: "Roger & Hope Turigye",
      title: "Deacons - Finance",
      bio: "With such vast experience and expertise in finance, Roger and Hope are such a resource. They are responsible for overseeing the finances of the ministry.",
      photo: TurigyePhoto,
      id: 7
    },{
      name: "Wilson & Deborah Kiggundu",
      title: "Technology & Media",
      bio: "Technology and Media are a very big part of our strategy. Wilson and Deborah have dedicated their time and skill towards leading and guiding our media teams.",
      photo: Cover,
      id: 8
    }
  ]

  return (
    <>
      <section id="team">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-6">
              <div className="text-left">
                <div className="about-sub-header">Our Leadership team</div>
              </div>
            </div>
          </div>
          <div className="row blog-home">
            {members.map((category) => {
              return CategoryCard(category);
            })}
          </div>
        </div>
      </section>
    </>
  );
}

function CategoryCard(category) {
  return (
    <div className="col-12 col-lg-3 res-margin" key={category.id}>
      <div className="team-wrapper">
        <div className="team-photo">
            <img src={category.photo} className="blog-img" alt="" />
        </div>

        <div className="team-body">
            <div className="team-name">
              {category.name}
            </div>
            <div className="team-title">
              {category.title}
            </div>
            <div className="team-bio">
              {category.bio}
            </div>
        </div>
      </div>
    </div>
  );
}

export default Team;
