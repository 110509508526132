import { put, takeLatest, call } from "redux-saga/effects";
import { GET_USERS, CREATE_USER, UPDATE_USER, SEARCH_USERS, SIGNUP, SIGNUP_SUCCESS, SIGNUP_FAILURE } from "../actions/actionTypes";
import UserAPI from "../../services/UserAPI";
import {
  fetchUsersSuccess,
  fetchUsersFailure,
  createUserSuccess,
  createUserFailure,
  updateUserSuccess,
  updateUserFailure,
  searchUsersSuccess,
  signupSuccess,
  signupFailure
} from "../actions/userActions";
import apiErrorHandler from "../../services/apiErrorHandler";

export function* fetchUsersAsync(action) {
  try {
    const response = yield call(UserAPI.fetch, action.userType);
    yield put(fetchUsersSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(fetchUsersFailure(errorMessage));
  }
}

export function* searchUsersAsync(action) {
  try {
    const response = yield call(UserAPI.search, action.searchParams);
    yield put(searchUsersSuccess(response.data));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(fetchUsersFailure(errorMessage));
  }
}

export function* createUserAsync(action) {
  try {
    const response = yield call(UserAPI.createUser, action.user);
    yield put(createUserSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(createUserFailure(errorMessage));
  }
}

export function* signupAsync(action) {
  try {
    const response = yield call(UserAPI.signup, action.user);
    yield put(signupSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(signupFailure(errorMessage));
  }
}

export function* updateUserAsync(action) {
  try {
    const response = yield call(UserAPI.updateUser, action.user);
    yield put(updateUserSuccess(response.data));
  } catch (error) {
    console.log(error);
    const errorMessage = apiErrorHandler(error);
    yield put(updateUserFailure(errorMessage));
  }
}

export function* watchSearchUsers() {
  yield takeLatest(SEARCH_USERS, searchUsersAsync);
}

export function* watchUsers() {
  yield takeLatest(GET_USERS, fetchUsersAsync);
}

export function* watchNewUser() {
  yield takeLatest(CREATE_USER, createUserAsync);
}

export function* watchSignup() {
  yield takeLatest(SIGNUP, signupAsync);
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUserAsync);
}

