import axios from "axios";
import resolveBaseUrl from ".";
import { randomPassword } from "../utils/Settings";

const baseUrl = resolveBaseUrl();

class UserAPI {
  static login(credentials) {
    return axios.post(`${baseUrl}/auth/login`, { username: credentials.username, password: credentials.password });
  }

  static fetch(userType) {
    return axios.get(`${baseUrl}/api/users?type=${userType}`);
  }

  static search(searchParams) {
    const {
      name = "",
      phone = "",
      role  = "",
      page = 1
    } = searchParams;
    return axios.get(`${baseUrl}/api/users?role=${role}&name=${name}&phone=${phone}&page=${page}`);
  }

  static fetchOtp(credentials) {
    return axios.post(`${baseUrl}/auth/otp`, { phone: credentials.email });
  }

  static loginOtp(credentials) {
    return axios.post(`${baseUrl}/auth/otp/login`, { phone: credentials.email, otp: credentials.password });
  }

  static editComment(commentData, id) {
    return axios.put(`${baseUrl}/comments/${id}`, commentData);
  }

  static deleteComment(commentId) {
    return axios.delete(`${baseUrl}/comments/${commentId}`);
  }

  static createUser(user) {
    if (user.phone && user.phone.length > 0) {
      if (!user.username || user.username.length < 3) {
        user.username = user.phone;
      }
      if (!user.email || user.email.length == 0) {
        user.email = user.phone + "@encounterchurchug.com";
      }
    }

    if (!user.password || user.password.length < 6) {
      user.password = randomPassword();
    }
    
    return axios.post(`${baseUrl}/api/users`, user);
  }

  static signup(user) {
    console.log("API call");
    return axios.post(`${baseUrl}/auth/signup`, user);
  }  

  static updateUser(user) {
    return axios.put(`${baseUrl}/api/users`, user);
  }
}

export default UserAPI;
