import { put, takeLatest, call } from "redux-saga/effects";
import { LOGIN, FETCH_OTP, LOGIN_OTP } from "../actions/actionTypes";
import UserAPI from "../../services/UserAPI";
import { loginSuccess, loginFailure, fetchOtpSuccess, fetchOtpFailure } from "../actions/userActions";
import apiErrorHandler from "../../services/apiErrorHandler";

export function* loginAsync(action) {
  try {
    const { user } = action;
    const { email, password, username } = user;
    const response = yield call(UserAPI.login, {
      username,
      password
    });
    yield put(loginSuccess(response));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(loginFailure(errorMessage));
  }
}

export function* loginOtpAsync(action) {
  try {
    const { user } = action;
    const { email, password } = user;
    const response = yield call(UserAPI.loginOtp, {
      email,
      password
    });
    yield put(loginSuccess(response));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(loginFailure(errorMessage));
  }
}

export function* fetchOtpAsync(action) {
  try {
    const { user } = action;
    const { email, password } = user;
    const response = yield call(UserAPI.fetchOtp, {
      email,
      password
    });
    yield put(fetchOtpSuccess(response));
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    yield put(fetchOtpFailure(errorMessage));
  }
}

export function* watchLogin() {
  yield takeLatest(LOGIN, loginAsync);
}

export function* watchFetchOtp() {
  yield takeLatest(FETCH_OTP, fetchOtpAsync);
}

export function* watchLoginOtp() {
  yield takeLatest(LOGIN_OTP, loginOtpAsync);
}
