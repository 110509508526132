/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import LoadingSpinner from "../common/LoadingSpinner";
import { convertShortDate, formattedDate } from "../../utils/Settings";
import DatePicker from "react-datepicker";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedRoles: [] };
  }

  render() {
    const classes = useStyles;

    const {
      handleSubmit,
      handleChange,
      littleMember,
      handleClickRegisterParent,
      title = "New User",
      isLoading = false,
      users = [],
    } = this.props;
    const roleOptions = [
      { label: "ADMIN", value: "admin" },
      { label: "NORMAL USER", value: "littleMember" },
      { label: "VOLUNTEER", value: "volunteer" },
    ];

    let loadingSpinner = "";
    if (isLoading) {
      loadingSpinner = (
        <span>
          <LoadingSpinner />
          <span> </span>
        </span>
      );
    }
    return (
      <Fragment>
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-body">
            <form className={classes.root} noValidate autoComplete="off">
              <div className="row">
                <div className="col-6">
                  <input
                    placeholder="First Name"
                    className="form-control"
                    onChange={handleChange}
                    value={littleMember.firstname}
                    name="firstname"
                  />
                </div>
                <div className="col-6">
                  <input
                    placeholder="Last Name"
                    className="form-control"
                    onChange={handleChange}
                    value={littleMember.lastname}
                    name="lastname"
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-12">
                  <DatePicker
                    name="dob"
                    valueName="selected"
                    placeholderText="Date of Birth"
                    className="form-control"
                    dateFormat="yyyy-MM-dd"
                    portalId="root-portal"
                    value={littleMember.dob ? littleMember.dob : ""}
                    selected={
                      littleMember.dob
                        ? convertShortDate(littleMember.dob)
                        : new Date()
                    }
                    onChange={(date) => {
                      const event = {
                        target: {
                          name: "dob",
                          value: formattedDate(date),
                        },
                      };
                      handleChange(event);
                    }}
                  />
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-8">
                  <select
                    name="user"
                    className="form-control"
                    onChange={handleChange}
                    value={littleMember.user && littleMember.user.id ? littleMember.user.id : ""}
                  >
                    <option value="">Parent / Guardian</option>
                    {users.map((user) => {
                      return (
                        <option value={user.id} key={user.id}>
                          {user.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-4">
                  <a
                    className="a-action-primary"
                    href="#"
                    onClick={() => {
                      handleClickRegisterParent();
                    }}
                  >
                    [+Register]
                  </a>
                </div>
              </div>
              <br />
              <div className="row">
                <div className="col-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => handleSubmit(event, this.country)}
                  >
                    {loadingSpinner} Save
                  </Button>
                </div>
              </div>
            </form>
            <p>&nbsp;</p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserForm;
