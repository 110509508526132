import React from "react";
import { Link } from "react-router-dom";
import Cover from "../../assets/images/cover.png";
import { Settings, formatDate } from "../../utils/Settings";
import { Ellipsis } from "react-simple-ellipsis";

function Featured(props) {
  const { events } = props;

  return (
    <>
      <section id="media">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6">
              <div className="section-title text-center">
                <h3>Jesus Reigns</h3>
                <p>
                  <q>
                    And they overcame him by the blood of the Lamb and by the
                    word of their testimony, and they did not love their lives
                    to the death
                  </q>{" "}
                  - Rev 12:11
                </p>
              </div>
            </div>
          </div>
          <div className="row blog-home">
            {events.map((category) => {
              return CategoryCard(category);
            })}
          </div>
        </div>
      </section>
    </>
  );
}

function CategoryCard(category) {
  return (
    <div className="col-12 col-lg-4 res-margin" key={category.id}>
      <div className="blog-col">
        <p>
          {category.conferenceUrl ?
            <>
              <iframe
                width="100%"
                src={category.conferenceUrl}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
              <span className="blog-category">{category.type}</span>
            </> : <>
              <img src={(category.cover != null) ? Settings.getFile(category.cover.path) : Cover} className="blog-img" alt="" />
            </>
          }
        </p>

        <div className="blog-wrapper1">
          <div className="blog-text">
            <h4>
              <Ellipsis
                ellipsis="..."
                label=""
                id={category.id}
                text={category.title}
                limit={55}
                class="more"
                mode={1}
              />
            </h4>
            <p className="blog-about">
              <span>{category.location}</span>
              <span>{formatDate(category.startDateTime)}</span>
            </p>
            <p>
              {category.details}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Featured;
