/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import parse from 'html-react-parser';
import { Link } from "react-router-dom";
import moment from 'moment';
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import withLoading from "../Hoc/withLoading";
import contentsLoader from "../common/Contentloader";
import Pagination from "react-js-pagination";
import "./Contents.scss";
import Cover from "../../assets/images/cover.png";
import { Settings } from "../../utils/Settings";
import { isAdmin } from "../../utils/Authentication";

const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 640,
  },
});

class ContentList extends React.Component {
  handleChangePage = () => {};
  handleChangeRowsPerPage = () => {};
  getCategoryName = (id) => {
    const { categories } = this.props;
    const category = categories.filter((category) => category.id === id)[0];
    return category.name;
  };
  render() {
    const classes = useStyles;
    const { contents, activePage = 1, totalItemsCount = 0, itemsCountPerPage = 30, handlePageClick = () => {} } = this.props;
    const page = 0;
    const rowsPerPage = 30;
    return (
      <Fragment>
        <Paper className={classes.root}>
          <div id="blog">
            <div className="container">
              <div className="row blog-home">
                {contents.map((content) => {
                  return CategoryCard(content);
                })}
              </div>
            </div>
          </div>
        </Paper>
        <div className="pagination-wrapper row">
          <div className="col-2 label">
            Found {totalItemsCount} item{totalItemsCount == 1 ? "": "s"}
          </div>
          <div className="col">
            <Pagination
              activePage={activePage}
              itemsCountPerPage={itemsCountPerPage}
              totalItemsCount={totalItemsCount}
              pageRangeDisplayed={5}
              onChange={(pageNumber) => {handlePageClick(pageNumber)}}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

function label(content) {
  const videos  = content.uploads.filter(u => u.path.endsWith('.mp4'));
  const pdfs  = content.uploads.filter(u => u.path.endsWith('.pdf'));   
  
  let lb = content.details;

  if (lb == null || lb.length === 0) {
    if (videos.length > 0) {
      lb = videos[0].name;
    } else if(pdfs.length > 0) {
      lb = pdfs[0].name;
    }
  }

  return lb;
}

function formatDate(value){
  if (value) {
    return moment(String(value)).format('MMMM Do YYYY, h:mm')
  }
}

function CategoryCard(content) {
  return (
	<div className="col-12 col-lg-4 res-margin" key={content.id}>			
		<div className="blog-col">
			<p>
        <Link
          to={{
            pathname: `/content/${content.id}`,
            state: {
              content: content,
            },
          }}
          >
					<img src={(content.cover != null) ? Settings.getFile(content.cover.path) : Cover} className="blog-img" alt="" />
				</Link>
				<span className="blog-category">
          {content.category}
          {isAdmin() ? (content.active ? " [Published]" : " [Not published]") : <></>}
        </span>
			</p>
			
			<div className="blog-wrapper">								
				<div className="blog-text">
					<h4>
          <Link
          to={{
            pathname: `/content/${content.id}`,
            state: {
              content: content,
            },
          }}
          >
            <span>{content.title}</span>
          </Link>
					</h4>
          <p className="blog-about">
						<span>{formatDate(content.created)}</span>
					</p>
					
          {parse(label(content))}
				</div>
			</div>
		</div>
	</div>
  )
}

export default withLoading(ContentList, contentsLoader);
