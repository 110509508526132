/* eslint-disable react/forbid-prop-types */
import React, { Fragment } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import MultiSelect from "react-multi-select-component";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import LoadingSpinner from "../common/LoadingSpinner";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: 200,
    },
  },
}));

class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedRoles: [] };
  }

  render() {
    const classes = useStyles;

    const { handleSubmit, handleChange, user, title = "New User", isLoading = false } = this.props;
    const roleOptions = [
      { label: "ADMIN", value: "admin" },
      { label: "NORMAL USER", value: "user" },
      { label: "VOLUNTEER", value: "volunteer" },
    ];
    const setRoles = (data) => {};
    let loadingSpinner = "";
    if (isLoading) {
      loadingSpinner = (
        <span>
          <LoadingSpinner />
          <span> </span>
        </span>
      );
    }
    return (
      <Fragment>
        <div className="card">
          <div className="card-header">{title}</div>
          <div className="card-body">
            <form className={classes.root} noValidate autoComplete="off">
              <div className="row">
                <div className="col-6">
                  <input
                    placeholder="Name"
                    className="form-control"
                    onChange={handleChange}
                    value={user.name}
                    name="name"
                  />
                </div>
                <div className="col-6">
                  <input
                    placeholder="Phone no: +256762777777"
                    className="form-control"
                    onChange={handleChange}
                    name="phone"
                    value={user.phone}
                  />
                </div>
              </div><br/>
              <div className="row">
                <div className="col-12">
                  <input
                    placeholder="Email"
                    className="form-control"
                    onChange={handleChange}
                    value={user.email}
                    name="email"
                  />
                </div>
              </div><br/>
              <div className="row">
                <div className="col-6">
                <MultiSelect
                    options={roleOptions}
                    value={this.state.selectedRoles}
                    onChange={(data) => {
                      this.setState({ selectedRoles: data });
                      handleChange({
                        target: {
                          name: "role",
                          value: data.map((r) => r.value),
                        },
                      });
                    }}
                    labelledBy={"Select role(s)"}
                  />
                </div>
                <div className="col-6">
                  <input
                    placeholder="Password"
                    className="form-control"
                    onChange={handleChange}
                    name="password"
                    type="password"
                  />
                </div>
              </div><br/> 
              <div className="row">
                <div className="col-4">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={(event) => handleSubmit(event, this.country)}
                  >
                    {loadingSpinner} Save
                  </Button>
                </div>
              </div>
            </form>
            <p>&nbsp;</p>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default UserForm;
