/* eslint-disable react/forbid-prop-types */
import React from "react";
import LoadingSpinner from "../common/LoadingSpinner";

export default class LoginForm extends React.Component {
  render() {
    const {
      error,
      isLoading = false,
      handleSubmit,
      handleChange,
      user = { username: "", password: "" }
    } = this.props;
    const { username, password } = user;
    let loadingSpinner = "";
    if (isLoading) {
      loadingSpinner = (
        <span>
          <LoadingSpinner />
          <span> </span>
        </span>
      );
    }
    return (
      <div className="login">
        <div className="card">
          <div class="card-header"><h3>Login</h3></div>
          <div className="card-body">
            {error && error.length > 0 ? error : ""}
            <div>
              <input type="text" placeholder="Username / Phone number" onChange={handleChange} value={username} name="username" className="form-control"/>
            </div><br/>
            <div>
              <input type="password" placeholder="Password" onChange={handleChange} value={password} name="password" className="form-control"/>
            </div><br/>
            <button className="btn" onClick={(event) => {handleSubmit(event);}}>{loadingSpinner} Proceed</button>
          </div>
        </div>
      </div>
    );
  }
}
